import axios from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";

export default class ShopMemberService {
    get(query: PaginationQuery): Promise<GridResult<IShopMemberRowDTO>> {
        return mapToDataOnly<GridResult<IShopMemberRowDTO>>(axios.get(`/Buyer/ShopMember?pageskip=${query.pageskip - 1}&pagesize=${query.pagesize}`));
    }

    delete(memberId: string): Promise<any> {
        return mapToDataOnly<any>(axios.delete(`/Buyer/ShopMember/${memberId}`));
    }

    create(dto: { email: string }, returnUrl: string): Promise<{}> {
        return mapToDataOnly<{}>(axios.post(`/Buyer/ShopMember?returnUrl=${returnUrl}`, dto));
    }
}

interface IShopMemberRowDTO {
    id: string;
    name: string;
    email: string;
    privileges: string[];
}

export interface IShopMemberBasePrivilegeDTO {
    id: string;
    title: string;
    shortTitle: string;
    isNumberValue: boolean;
}
import React from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import FinancialManagementService from "../../../../services/financials/FinancialManagementService";
import IState, { IProps } from "./FinancialAccountVerifyModal.models";
import HubridMoment from "../../../utilities/HybridMoment";

export default class FinancialAccountVerifyModal extends React.Component<IProps, IState> {
    financialManagementService: FinancialManagementService
    constructor(props: any) {
        super(props);
        this.state = { destinationWallet: '', accountAddress: '', transactionNumber: '', loading: false, pendingRequest: false }
        this.financialManagementService = new FinancialManagementService()
    }

    componentDidMount = () => {
        this.setState({ loading: true })
        this.financialManagementService.getVerifyAmount(this.props.accountId).then(response =>
            this.setState({ amount: response.amount, expireAt: response.expireAt, accountAddress: response.cashPaymentAccountNumber })
        ).finally(() => this.setState({ loading: false }))
    }

    checkPayment = () => {
        this.setState({ pendingRequest: true })

        this.financialManagementService.checkPayment(this.state.transactionNumber, this.props.accountId).then(response => {
            this.setState({ amount: response.amount, expireAt: response.expireAt })
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { transactionNumber, pendingRequest, accountAddress, expireAt} = this.state

        return <Modal show={true} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Verfiy External Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {
                        this.state.loading ?
                            <Spinner animation='grow' />
                            :
                            <Row>
                                <Col md="12" sm="12">
                                    <Alert variant="success">
                                        <Alert.Heading>How to verify the external wallet?</Alert.Heading>
                                        In order to automatically verify your wallet deposits,
                                        transfer the amount of <b> {this.state.amount} </b> currency (USDT)
                                        from wallet <code> {this.props.sourceWallet} </code> to the wallet <code> {accountAddress} </code>
                                        within the next 48 hours.                                        
                                    </Alert>
                                </Col>
                                <Col className="mt-3" md="12" sm="12">
                                    <p>If you have paid the amount, enter the transaction number and confirm.</p>
                                    <Form.Label>Transaction Number:</Form.Label>
                                    <Form.Control className="mb-3" value={transactionNumber} onChange={e => this.setState({ transactionNumber: e.target.value })} />
                                </Col>
                            </Row>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='light' onClick={this.props.onClose}>Close</Button>
                {
                    pendingRequest ? <Spinner animation="border" /> :
                        <Button variant='success' onClick={this.checkPayment}>Confirm</Button>
                }
            </Modal.Footer>
        </Modal>
    }
}
import React from "react"
import CardView from "../../../../shared/Card/CardView"
import { Chart } from "../../../../shared/Chart/Chart"
import { Placeholder, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import './SalesStatisticsChart.scss'
import AdminOrderProductService, { TimePeriod } from "../../../../../services/admin/orders/AdminOrderProductService"
import moment from "moment"

export default class SalesStatisticsChart extends React.Component<{ title: string, onlyShared?: boolean, colors?: string[] }, { filterBy: any, loading: boolean, data: any }> {
    orderProductService: AdminOrderProductService

    constructor(props: any) {
        super(props)

        this.state = { loading: false, data: {}, filterBy: TimePeriod.Monthly }

        this.orderProductService = new AdminOrderProductService()
    }

    componentDidMount(): void {
        this.onChangeDate(TimePeriod.Monthly)
    }

    setChartData = (fromDate?: any, toDate?: any) => {
        this.setState({ loading: true })
        this.orderProductService.getStatistics(this.props.onlyShared ?? false, fromDate, toDate, this.state.filterBy).then((response) => {
            this.setState({ data: response })
        }).finally(() => this.setState({ loading: false }))
    }

    onChangeDate = (value: TimePeriod) => {
        this.setState({ filterBy: value }, () => {
            const fromDate = new Date(new Date());

            switch (value) {
                case TimePeriod.Daily:
                    return this.setChartData()
                case TimePeriod.Weekly:
                    fromDate.setDate(new Date().getDate() - 7);
                    return this.setChartData(fromDate.toISOString())
                case TimePeriod.Monthly:
                    return this.setChartData(moment().startOf('month').toDate())
                case TimePeriod.Yearly:
                    fromDate.setFullYear(new Date().getFullYear() - 5);
                    return this.setChartData(fromDate.toISOString())
            }
        })
    }

    chartArea = (name: string, data: any) => {
        const chartData = [
            {
                name: name,
                data: Object.values(data)
            }
        ];
        return (
            <CardView small card={{ header: name }}>
                {
                    this.state.loading ?
                        <Placeholder animation="glow" className="product-image">
                            <Placeholder xs={12} style={{ height: '300px', borderRadius: '8px' }} />
                        </Placeholder> :
                        <Chart
                            dataSeries={chartData}
                            categories={Object.keys(data)}
                            colors={this.props.colors}
                        />
                }
            </CardView>
        )
    }

    render(): React.ReactNode {
        let filters: { title: string, value: TimePeriod }[] = [{ title: 'Monthly', value: TimePeriod.Monthly }, { title: 'Yearly', value: TimePeriod.Yearly }]
        let { filterBy } = this.state

        return (
            <>
                <ToggleButtonGroup
                    type="checkbox"
                    name="ToggleButtonGroup"
                    color="success"
                    className="mb-3"
                    value={filterBy}
                >
                    {
                        filters.map((filter) => {
                            return <ToggleButton onClick={() => { this.onChangeDate(filter.value) }} key={filter.value}
                                className={`toggle-bottom btn-light ${filter.value == filterBy && " active-filter"}`} value={filter.value}>
                                {filter.title}
                            </ToggleButton>

                        })
                    }
                </ToggleButtonGroup>
                {this.chartArea(this.props.title, this.state.data)}
            </>
        )
    }
}
import React from "react";
import './AdminHeader.scss';
import IdentityService from "../../../../services/identity/IdentityService";
import { WalletOverview } from "../../Header/WalletOverview/WalletOverview";
import LayoutContext from "../../../../contexts/LayoutContext/LayoutContext";
import ShopChanger from "../../ShopChanger/ShopChanger";
import ShopPolicies from "../../../shared/policy/ShopPolicies/ShopPolicies";
import Alerts from "../../../dashboard/Alerts/Alerts";
import { SELLER } from "../../../../constants/ConstantsPolicies";
import Profile from "../../Profile/Profile";
import AdminNavMenu from "./NavMenu/AdminNavMenu";

export default class AdminHeader extends React.Component<{ onToggleSidebar(): void, hideMenu: boolean, isMobile: boolean }, { showDropDown: boolean }> {
    identityService: IdentityService;
    walletOverview: React.RefObject<WalletOverview>;
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;

    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.state = { showDropDown: false };
        this.walletOverview = React.createRef()
    }

    componentDidMount(): void {
        this.context.onAddToCartSubject.subscribe(() => {
            this.walletOverview.current?.loadOverview();
        })
    }


    mobileView = () => {
        return <header>
            <div className="nav-area">
                {
                    !this.props.hideMenu && <>
                        <AdminNavMenu><WalletOverview ref={this.walletOverview}></WalletOverview></AdminNavMenu>
                    </>
                }
            </div>
            <div className="shop-overview-selection">
                <ShopChanger />
            </div>
            <div className="profile">
                <Profile />
            </div>
            <div className="header-alerts">
                <Alerts />
            </div>
        </header>;
    }

    defaultView = () => {
        return <header>
            <div className="shop-overview-selection">
                <ShopChanger />
            </div>
            <div className="nav-area">
                {
                    !this.props.hideMenu && <AdminNavMenu />
                }
            </div>
            <div className="profile">
                <Profile />
            </div>
            <div className="header-alerts">
                <Alerts />
            </div>
        </header>;
    }

    render(): React.ReactNode {
        return this.props.isMobile ? this.mobileView() : this.defaultView();
    }
}
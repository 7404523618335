import axios from "axios";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";
import { IOrderItemReportDto } from "../../components/helpCenter/Reports/Details/ReportDetails.models";
import { IReviewRequestPaginationFilters, IShopReportSupportTicketQueryString, SupportTicketStatus } from "../../components/helpCenter/Reports/ReportsList/ReportsList.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { GridResult } from "../../components/Grid/Grid.models";

export default class ReviewRequestService {
    
    accept(ticketId: string): Promise<void> {
        return mapToDataOnly(axios.put(`ReviewRequest/${ticketId}/Accept`));
    }

    startMediation(ticketId: string): Promise<void> {
        return mapToDataOnly(axios.put(`ReviewRequest/${ticketId}/StartMediation`));
    }
    getById(ticketId: string): Promise<IOrderItemReportDto> {
        return mapToDataOnly<IOrderItemReportDto>(axios.get(`/ReviewRequest/${ticketId}/Sell`));
    }

    getSellPaginated(filters: IReviewRequestPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IShopReportSupportTicketDTO>> {
        return mapToDataOnly<GridResult<IShopReportSupportTicketDTO>>(axios.get(`/ReviewRequest/Sell${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getBuyPaginated(query: IShopReportSupportTicketQueryString): Promise<GridResult<{
        createAt: string
        id: string
        memberDisplayName: string
        modifyAt: string
        status: number
        title: string,
        tags: string[]
    }>> {
        return mapToDataOnly<GridResult<any>>(axios.get(`ReviewRequest/Buy${mapQueryString(query)}`));
    }
    getCount(): Promise<ISupportTicketCountDTO> {
        const status: SupportTicketStatus = SupportTicketStatus.TargetPending;
        return mapToDataOnly<ISupportTicketCountDTO>(axios.get(`ReviewRequest/Sell/Count?status=${status}`));
    }
}

interface IShopReportSupportTicketDTO {
    id: string
    title: string
    userDisplayName: string
    modifyAt: string
    createAt: string
    status: SupportTicketStatus,
    isMediationRequested: boolean,
    tags: string[]
}


interface ISupportTicketCountDTO {
    count: number,
    forceCount: number
}

import { useSearchParams } from "react-router-dom"
import AdminWalletCharge from "../../../components/admin/financials/AdminWallets/Charge/AdminWalletCharge"
import AdminAuthenticated from "../../../components/shared/AdminAuthenticated/AdminAuthenticated"
import { ADMIN, SUPERADMIN } from "../../../constants/ConstantsPermissions"

export default function AdminWalletChargeRoute() {
    const [searchParams] = useSearchParams()
    let walletId = searchParams.get("id")

    return <AdminAuthenticated roles={[SUPERADMIN, ADMIN]}><AdminWalletCharge walletId={walletId} /></AdminAuthenticated>
}
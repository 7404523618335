import React from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import ProductCategoryService, { IUpdateShopProductCategoryFavoriteDTO } from "../../../services/category/ProductCategoryService";
import { SelectionView } from "../../../services/common/Models";
import CardView from "../../shared/Card/CardView";
import RequestCreateCategoryModal from "../RequestCreateCategory/RequestCreateCategoryModal";
import IEditCategoryFavoriteState, { ICategoryFavoriteView, IEditCategoryFavoriteProps } from './EditCategoryFavorite.models'
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext"

export default class EditCategoryFavorite extends React.PureComponent<IEditCategoryFavoriteProps, IEditCategoryFavoriteState> {
    productCategoryService: ProductCategoryService
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    constructor(props: IEditCategoryFavoriteProps) {
        super(props)
        this.productCategoryService = new ProductCategoryService()
        this.state = { showModal: false, categories: [], isLoading: false, favorites: [], categoryFavorites: [] }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        let favoritesList: ICategoryFavoriteView[];
        this.setState({ isLoading: true })
        this.productCategoryService.getChildOnlyTitleValues().then((categories) => {
            this.productCategoryService.getMultiLevel().then((favorites) => {
                favoritesList = favorites.map((favorite, index) => {
                    return {
                        id: favorite.id,
                        title: favorite.title,
                        orderId: index + 1
                    } as ICategoryFavoriteView
                })

                this.setState({ categories, favorites, isLoading: false, categoryFavorites: favoritesList })
            })
        })
    }

    removeFavorite = (index: number) => {
        let tempCategoryFavorites = this.state.categoryFavorites
        tempCategoryFavorites.splice(index, 1)
        this.updateFavoriteList(tempCategoryFavorites)
    }

    addFavorite = (row: SelectionView) => {
        let tempCategoryFavorites = this.state.categoryFavorites
        tempCategoryFavorites.push({
            id: row.value,
            title: row.title,
            orderId: this.state.categoryFavorites.length + 1
        })
        this.updateFavoriteList(tempCategoryFavorites)
    }

    moveUp = (index: number) => {
        let tempCategoryFavorites = this.state.categoryFavorites
        tempCategoryFavorites[index - 1].orderId++
        tempCategoryFavorites[index].orderId--
        tempCategoryFavorites.sort((a, b) => a.orderId - b.orderId)
        this.updateFavoriteList(tempCategoryFavorites)
    }

    moveDown = (index: number) => {
        let tempCategoryFavorites = this.state.categoryFavorites
        tempCategoryFavorites[index + 1].orderId--
        tempCategoryFavorites[index].orderId++
        tempCategoryFavorites.sort((a, b) => a.orderId - b.orderId)
        this.updateFavoriteList(tempCategoryFavorites)
    }

    updateFavoriteList = (list: ICategoryFavoriteView[]) => {
        this.setState({ categoryFavorites: list })
        this.forceUpdate()
        this.saveChanges()
    }

    saveChanges = () => {
        let body: IUpdateShopProductCategoryFavoriteDTO[] = this.state.categoryFavorites.sort((a, b) => a.orderId - b.orderId).map(favorite => {
            return {
                orderId: favorite.orderId,
                productCategoryId: favorite.id
            } as IUpdateShopProductCategoryFavoriteDTO
        })

        this.productCategoryService.updateFavorite(body).then(() => {
            this.context.reloadSideBar()
        })
    }

    render(): React.ReactNode {
        return <>
            <Row>
                <Col md="6" sm="12">
                    <h3>Edit category favorite</h3>
                    <span className="text-muted">After making changes in this section, be sure to save the changes</span>
                </Col>
                <Col md="6" sm="12" className="text-end">
                    <Button variant="outline-success" size="sm" className="m-1" onClick={() => this.setState({ showModal: true })}>Request Create Category</Button>
                </Col>
            </Row>
            {this.state.isLoading ? <div className="text-center"><Spinner animation="border" /></div> :
                <Row className="pt-4">
                    <Col md="6" sm="12">
                        <CardView card={{ header: 'Favorites' }} renderHeader={(<>
                            <div className="text-muted">List of all your favorite categories</div>
                        </>)}>
                            <Table striped hover responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.categoryFavorites.map(((favorite, index) => {
                                        return <tr key={favorite.id}>
                                            <td>{favorite.title}</td>
                                            <td className="text-end">
                                                <Button variant="warning" size="sm" onClick={() => this.removeFavorite(index)}>Remove</Button>
                                                <Button variant="link" onClick={() => this.moveDown(index)}><ArrowDown className="text-secondary" /></Button>
                                                <Button variant="link" onClick={() => this.moveUp(index)}><ArrowUp className="text-secondary" /></Button>
                                            </td>
                                        </tr>
                                    }))}
                                </tbody>
                            </Table>
                        </CardView>
                    </Col>
                    <Col md="6" sm="12">
                        <CardView card={{ header: "Other categories" }} renderHeader={(<div className="text-muted">List of all another categories</div>)}>
                            <Table striped hover responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.categories.filter(category => !this.state.categoryFavorites.filter(favorite => favorite.id === category.value)[0]).map((category => {
                                        return <tr key={category.value}>
                                            <td>{category.title}</td>
                                            <td><Button variant="success" size="sm" onClick={() => this.addFavorite(category)}> Add</Button></td>
                                        </tr>
                                    }))}
                                </tbody>
                            </Table>
                        </CardView>
                    </Col>
                </Row>}
            {this.state.showModal && <RequestCreateCategoryModal closeModal={() => this.setState({ showModal: false })} />}
        </>
    }
}
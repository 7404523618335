import axios from "axios";
import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";

export default class OrderProductService {
    getStatistics(productId: string): Promise<OrderStatisticsDTO> {
        return mapToDataOnly<OrderStatisticsDTO>(axios.get(`/Seller/OrderProduct/Statistics/${productId}`));
    }

    getProductPriceCompare(productIds: string[]): Promise<{
        productId: string,
        yourPrice: number,
        minimumOtherPrice: number,
        minimumOtherOfferPrice: number,
    }[]> {
        var params = new URLSearchParams();
        for (var productId of productIds)
            params.append("productIds", productId);

        return mapToDataOnly<any>(axios.get(`/ProductPrice/Compare`, { params: params}));
    }

    getStatisticsList(queryString: IProductListQueryString): Promise<{ products: ProductDetails[], totalRows: number }> {
        return mapToDataOnly<any>(axios.get(`/Marketplace/Product${mapQueryString(queryString)}`));
    }

    getExpressCatalog(queryString: IGetExpressCatalogQueryString): Promise<{ products: IExpressProductCatalogDto[] }> {
        return mapToDataOnly<any>(axios.get(`/ProductCatalog/Express${mapQueryString(queryString)}`));
    }

    getOrderCount(): Promise<OrderCountDTO> {
        return mapToDataOnly<OrderCountDTO>(axios.get(`/Seller/OrderProduct/Count`));
    }

    getPendingCount(): Promise<OrderCountDTO> {
        let url = `/Seller/OrderProduct/Count/Pending`
        return mapToDataOnly<OrderCountDTO>(axios.get(url));
    }

    handleOrderSuggestionSetting(dto: IOrderSuggestionUpdateSettingDTO[]): Promise<{}> {
        let url = `/Seller/OrderProduct/OrderSuggestion/Handle`
        return mapToDataOnly<{}>(axios.put(url, dto));
    }
}

export interface IProductListQueryString {
    productCategoryId?: string,
    fetchLimit?: number,
    sortBy?: string
    search?: string
    productId?: string
    productTitle?: string
    onlyAvailable?: boolean
    pageSkip: number,
    pageSize: number
}


export interface IGetExpressCatalogQueryString {
    productCategoryId?: string,
    productId?: string
    productTitleSearch?: string
}



export interface IOrderSuggestionUpdateSettingDTO {
    productId: string;
    hasStar: boolean;
    hasPendingOnly: boolean;
}

export interface OrderStatisticsDTO {
    dailyAverageCount: number
}

export interface ProductDetails {
    productId: string;
    categoryId: string;
    title: string;
    realPrice: number;
    createDate: string;
    currencyId: string;
    imageId: string | null;
    imageSrc: string | null;
    totalSoldCount: number;
    status: InventoryShopBasePlanStatusEnum;
    availableInventory: number;
    minimumInventory: number;
    dailySoldCountAverage: number;
    requiredCount: number;
    buyPendingCount: number;
    isPinned: boolean;
    buyWhenNeededOnly: boolean;
    buySuggestionCount: number;
}


export interface IExpressProductCatalogDto {
    id: string;
    categoryId: string;
    title: string;
    realPrice: number;
    createDate: string;
    currencyId: string;
    imageSrc: string | null;
}
interface OrderCountDTO {
    count: number
}
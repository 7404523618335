import React from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import NavLinkItem from "../../shared/NavLinkItem/NavLinkItem";
import './Footer.scss'

export default class FooterComponent extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return <div className="landing-footer">
            <Container>
                <Row>
                    <Col md="12" lg="6" sm="12">
                        <NavLink to="/" className="me-2 text-black font-bold fs-2">API Gift</NavLink>
                        <div className="text-grey pt-3">The widest variety of Gift Cards at cheapest prices ever!</div>
                    </Col>
                    <Col md="12" lg="6" sm="12">
                        <Nav className="ms-auto text-dark">
                            <div className="nav-link"><NavLink to="/about-us">About us</NavLink></div>
                            <div className="nav-link"><NavLink to="/contact-us">Contact us</NavLink></div>
                            <div className="nav-link"><NavLink to="/policy">Privacy policy</NavLink></div>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" sm="12" className="text-center content-end">
                        <span className="line-normal text-greyish-brown fs-7">All contents such as Images, text, Videos, Icons, etc. are subjected to API Gift website.
                            © 2019 - 2023 apigift.com - All Rights Reserved.</span>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}
import axios, { AxiosResponse } from "axios"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility"
import { SelectionView } from "../../common/Models"

let baseUrl = 'Admin/ArticleCategory'

export default class AdminArticleCategoryService {
    getPaged(): Promise<any> {
        return mapToDataOnly<any>(axios.get(`${baseUrl}`))
    }
    
    update(id: string, dto: IArticleCategoryDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.put(`${baseUrl}/${id}`, dto));
    }

    delete(id: string): Promise<AxiosResponse> {
        return mapToDataOnly(axios.delete(`${baseUrl}/${id}`));
    }

    add(dto: IArticleCategoryDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.post(`${baseUrl}`, dto));
    }

    getById(id: string): Promise<IArticleCategoryDTO> {
        return mapToDataOnly<IArticleCategoryDTO>(axios.get(`${baseUrl}/${id}`))
    }

    getTitleValues(): Promise<SelectionView[]> {
        let url = `${baseUrl}/TitleValue`
        return mapToDataOnly<SelectionView[]>(axios.get(url));
    }
}

export interface IArticleCategoryDTO {
    title: string
    isActive: boolean
    imageName: string
    parentId: string
    orderId: number
    imageSrc: string
}
import axios from "axios";
import { IEditUserPrivilegeDTO, IUserPrivilegesRowDTO } from "../../components/identity/UserPrivilege/UserPrivilege.models";
import { mapToDataOnly } from "../common/HttpUtility";

export default class UserPrivilegeService {
    updatePrivilege(dto: IEditUserPrivilegeDTO[], userId: string): Promise<{}> {
        return mapToDataOnly<{}>(axios.put(`/Identity/UserPrivilege/${userId}`, dto));
    }
    
    getUserPrivileges(userId: string): Promise<IUserPrivilegesRowDTO[]> {
        return mapToDataOnly<IUserPrivilegesRowDTO[]>(axios.get(`/Identity/UserPrivilege/${userId}`));
    }
}
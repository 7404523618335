import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";

export class LocalCurrencyService {

    getBalance(): Promise<{
        average: number
        balance: number
        currencySymbol: string
        currencyTitle: string
        currencyId: string
        totalBalance: number
        walletId: string
    }[]> {
        return mapToDataOnly<any>(axios.get(`/Buyer/Shop/ShopProperties`));
    }

    get(): Promise<{
        currencyKey: string
        currencySymbol: string
        currencyTitle: string
        localCurrencyId: string
    }> {
        return mapToDataOnly<any>(axios.get(`/Buyer/Shop/LocalCurrency`));
    }

    getTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`Buyer/Currency/TitleValue`));
    }

    setLocalCurrency(shopId: string, currencyId: string): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Buyer/Shop/LocalCurrency/${shopId}/${currencyId}`));
    }
}
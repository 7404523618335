import './App.scss';
import AuthenticationContext, { IAuthenticationContext } from './contexts/AuthenticationContext/AuthenticationContext';
import axios from 'axios';
import Routing from './routes/Routing';
import { toast, ToastContainer } from 'react-toastify';
import { AxiosConfig } from './components/utilities/AxiosConfig';
import { API_URL, GOOGLE_ANALYTICS_ID } from './constants/Constants';
import ReactGA from "react-ga4";

if (GOOGLE_ANALYTICS_ID)
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
/* This is to disable scrolling on number inputs */

document.addEventListener("wheel", function (event) {
  if ((document.activeElement as any).type === "number") {
    (document.activeElement as any).blur();
  }
});

/* End */


function App() {
  const authenticationContext: IAuthenticationContext = {
    twoFactorAvtive: false,
    privileges: [],
    policies: [],
    roles: [],
    role: localStorage.getItem('role') || 'buyer',
    user: { displayName: '', userName: '' },
    isAuthentication: () => localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null && localStorage.getItem('token') !== '',
    login: (token: string) => {
      localStorage.setItem('token', token);
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    },
    logout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('activeshop');
      delete axios.defaults.headers.common['authorization'];
    }
  };

  AxiosConfig(API_URL);

  return (
    <div className="App">
      <AuthenticationContext.Provider value={authenticationContext}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routing />
      </AuthenticationContext.Provider>
    </div>
  );
}

export default App;

import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility";

export default class SupportTicketTagService {

    create(dto: CreateSupportTicketTagDTO): Promise<void> {
        return mapToDataOnly(axios.post(`SupportTicketTag`, dto));
    }

    deleteById(id: string): Promise<void> {
        return mapToDataOnly<void>(axios.delete(`SupportTicketTag/${id}`));
    }

}

export interface CreateSupportTicketTagDTO {
    ticketId: string;
    key?: string;
    value: string;
}
import axios from "axios";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility";


export interface IFilters {
    shopTitle: string;
    isActive: boolean;
    negativeCreditsOnly: boolean;
}

export default class AdminCreditsService {
    getPaged(filters: IFilters, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(axios.get(`Admin/Credit${mapQueryString({ ...filters, ...paginationQuery })}` ));
    }

    add(body: {}): Promise<any> {
        return mapToDataOnly<any>(axios.post(`Admin/Credit`, body));
    }

    archive(id: string): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Admin/Credit/Archive/${id}`));
    }
}
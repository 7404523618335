import axios from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";

export default class ShopMemberInvitationService {
    get(filters: {onlyPendings: boolean}, query: PaginationQuery): Promise<GridResult<ShopMemberInvitationRowDto>> {
        return mapToDataOnly<GridResult<ShopMemberInvitationRowDto>>(axios.get(`/MarketPlace/ShopMemberInvite${mapQueryString({ ...filters, ...query })}`));
    }

    revoke(id: string): Promise<{}> {
        return mapToDataOnly<{}>(axios.delete(`/MarketPlace/ShopMemberInvite/Revoke/${id}`));
    }
}

interface ShopMemberInvitationRowDto {
    id: string;
    email: string;
    createDate: string;
    rejected: boolean;
}
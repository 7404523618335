import axios from "axios";
import { CashPaymentStatusEnum } from "../../components/financials/Enums/CashPaymentStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";

export default class DepositService {

    get(filters: IDepositsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<ICashPayment>> {
        return mapToDataOnly<GridResult<ICashPayment>>(axios.get(`/Buyer/CashPayment${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    delete(id: string) {
        return mapToDataOnly<{}>(axios.delete(`/Buyer/CashPayment/${id}`));
    }

    inquiry(hash: string): Promise<{ isExisting: boolean, details: TransactionInquiryView }> {
        let url = `/Buyer/CashPayment/Inquiry/${hash}`
        return mapToDataOnly<{ isExisting: boolean, details: TransactionInquiryView }>(axios.get(url));
    }
    create(dto: CreateDepositDto): Promise<{ paymentUrl: string }> {
        return mapToDataOnly<any>(axios.post(`Deposit`, dto));
    }

    verify(depositId: string): Promise<{ verified: boolean, amount: number }> {
        return mapToDataOnly<any>(axios.put(`Deposit/${depositId}`));
    }
}

export interface IDepositsPaginationFilters {
}
interface TransactionInquiryView {
    amount: number;
    token: string;
    toWalletAddress: string;
    isConfirm: boolean;
    fromWalletAddress: string;
    transactionDate: string;
}
interface ICashPayment {
    id: string,
    status: CashPaymentStatusEnum,
    invoiceId: string,
    amount: number,
    transactionNumber: string,
    crreateAt: Date,
    cashPaymentAccountTitle: string,
    purchasePrice: number,
    purchaseCurrency: string
}


interface CreateDepositDto {
    amount: number
    autoExchangeRequestWalletId: string
    cashPaymentAccountId: string
    description: string
    purchasePrice?: number
    callbackUrl: string
    refrenceNumber: string
}

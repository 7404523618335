import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";

export default class ProductCategoryService {
    getChildOnlyTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`/Buyer/ProductCategory/TitleValue/ChildOnly`));
    }

    getMultiLevel(): Promise<IProductCategoryMultiLevelDTO[]> {
        return mapToDataOnly<IProductCategoryMultiLevelDTO[]>(axios.get(`/MarketPlace/ProductCategoryFavorite/MultiLevel`));
    }

    updateFavorite(dto: IUpdateShopProductCategoryFavoriteDTO[]): Promise<{}> {
        return mapToDataOnly<{}>(axios.put(`/MarketPlace/ProductCategoryFavorite`, dto));
    }

    openSellRequest(productCategoryId: string): Promise<{}> {
        return mapToDataOnly<{}>(axios.post(`/Seller/ProductCategory/${productCategoryId}`));
    }
}

export interface IProductCategoryMultiLevelDTO {
    id: string;
    title: string;
    parentId: string | null;
}

export interface IUpdateShopProductCategoryFavoriteDTO {
    productCategoryId: string;
    orderId: number;
}
import axios from "axios";
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility";

export default class AdminMediationService {
    getDetails(ticketId, orderProductId): Promise<{decision: DecisionView | null}> {
        return mapToDataOnly<{decision: DecisionView | null}>(axios.get(`/Admin/Mediation/Details${mapQueryString({ ticketId, orderProductId })}`,));
    }
    create(dto: CreateDecisionDTO): Promise<any> {
        return mapToDataOnly<any>(axios.post(`/Admin/Mediation`, dto));
    }

    releaseSellerMoney(id: string): Promise<any> {
        return mapToDataOnly<any>(axios.post(`Admin/Mediation/ReleaseMoney/${id}/Seller` ));
    }

    releaseBuyerMoney(id: string): Promise<any> {
        return mapToDataOnly<any>(axios.post(`Admin/Mediation/ReleaseMoney/${id}/Buyer` ));
    }
}

interface DecisionView {
    id: string;
    creatorUserId: string;
    createDate: string;
    sellerLossAmount: number;
    buyerLossAmount: number;
}

interface CreateDecisionDTO {
    correctnessResult:CorrectnessResults;
    ticketId: string;
    orderProductId: string;
    sellerLossAmount: number;
    buyerLossAmount: number;
}

export enum CorrectnessResults {
    Buyer,
    Seller,
    Both
}
import axios, { AxiosResponse } from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { ShopInventoryStatusEnum } from "../../components/inventory/Enums/ShopInventoryStatusEnum";
import { InventoryFilters, IShopInventoryRowDTO } from "../../components/inventory/InventoriesList/InventoryList.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";
import { ITakeoutInventoryFilters } from "../../components/inventory/TakeoutInventory/TakeoutInventory.models";

export default class InventoryService {
    getPaged(filters: InventoryFilters, paginationQuery: PaginationQuery): Promise<GridResult<IShopInventoryRowDTO>> {
        return mapToDataOnly<GridResult<IShopInventoryRowDTO>>(axios.get(`/Seller/InventoryProduct${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getById(id: string): Promise<IShopInventoryRowDTO> {
        return mapToDataOnly<IShopInventoryRowDTO>(axios.get(`/Seller/InventoryProduct/Inventory/${id}`));
    }

    getInformation(id: string): Promise<{ information: string }> {
        let url = `/Seller/InventoryProduct/Information/${id}`
        return mapToDataOnly<any>(axios.get(url));
    }
    create(dto: IAddShopInventoryDTO): Promise<AddShopInventoryResultDTO> {
        let url = `/Seller/InventoryProduct/Item`
        return mapToDataOnly<AddShopInventoryResultDTO>(axios.post(url, dto))
    }

    unlock(inventoryId: string, duplicateInformationAccepted): Promise<any> {
        return mapToDataOnly(axios.put(`/Seller/InventoryProduct/Unlock/${inventoryId}`, { duplicateInformationAccepted } as IUnlockInventoryDto));
    }

    changeInventoryProduct(dto: IChangeInventoryProductDTO): Promise<any> {
        return mapToDataOnly(axios.put(`/Seller/InventoryProduct/Change`, dto));
    }

    holdInventoryProductByCollectionId(collectionId: string): Promise<any> {
        return mapToDataOnly(axios.put(`/Seller/InventoryProduct/Hold/Collection/${collectionId}`));
    }

    getTakeoutInventories(filters: ITakeoutInventoryFilters, paginationQuery: PaginationQuery): Promise<GridResult<TakeoutInventoryRowDTO>> {
        return mapToDataOnly<GridResult<TakeoutInventoryRowDTO>>(axios.get(`/MarketPlace/TakeoutInventory${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    createManualTakeoutInventory(dto: { inventoryId: string, description: string }): Promise<any> {
        return mapToDataOnly<any>(axios.post(`/MarketPlace/TakeoutInventory`, dto));
    }

    resendWebhook(id: string): Promise<any> {
        return mapToDataOnly(axios.put(`MarketPlace/TakeoutInventory/ResendWebhook/${id}`));
    }

    download(productId: string, query: { isOut: boolean, description: string }): Promise<void | AxiosResponse<any, any>> {
        return axios
            .post(`Seller/InventoryProduct/Details/Full/Excel/${productId}`, query, { responseType: 'blob' })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `product-inventory.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }
}
interface IAddShopInventoryResultErrorDTO {
    index: number;
    inventoryId: string;
    conflictFields: string[];
}

interface AddShopInventoryResultDTO {
    errors: IAddShopInventoryResultErrorDTO[];
}

export interface IAddShopInventoryDTO {
    withoutConflictValidation: boolean;
    productId: string;
    price?: number;
    buyDate?: Date;
    isDebt?: boolean;
    sourceId?: string | null;
    orderNumber?: string;
    description?: string;
    deficitCount?: number;
    inventoryItems: string[][];
}

export interface IChangeInventoryProductDTO {
    inventoryShopId: string;
    status: ShopInventoryStatusEnum;
}

interface IUnlockInventoryDto {
    duplicateInformationAccepted: boolean
}

export interface TakeoutInventoryRowDTO {
    id: string;
    productId: string;
    productTitle: string;
    inventoryShopId: string | null;
    inventoryShopTitle: string;
    isCanceled: boolean;
    isWebhookDelivered: boolean;
    webhookDeliveredLastError: string;
    sourceDocument: string;
    sourceKey: string;
    sourceSubKey: string;
    sourceDeliveryKey: string;
    rowVersion: string;
    createDate: any;
    lastProccess: string | null;
}
import axios, { AxiosResponse } from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";

export default class InventorySourceService {
    getPaged(queryString: PaginationQuery): Promise<GridResult<InventorySourceDTO>> {
        return mapToDataOnly<GridResult<InventorySourceDTO>>(axios.get(`/Seller/InventorySource?pageskip=${queryString.pageskip - 1}&pagesize=${queryString.pagesize}`));
    }

    getById(id: string): Promise<InventorySourceDTO> {
        let url =`/Seller/InventorySource/Update/${id}`
        return mapToDataOnly<InventorySourceDTO>(axios.get(url));
    }
    
    create(dto: ICreateInventorySourceDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.post(`/Seller/InventorySource`, dto));
    }

    update(dto: IUpdateInventorySourceDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.put(`/Seller/InventorySource`, dto));
    }

    delete(id: string): Promise<AxiosResponse> {
        return mapToDataOnly(axios.delete(`/Seller/InventorySource/${id}`));
    }

    getDetails(sourceId: string): Promise<InventorySourceDTO> {
        let url =`/Seller/InventorySource/${sourceId}`
        return mapToDataOnly<InventorySourceDTO>(axios.get(url));
    }

    getTitleValues(): Promise<SelectionView[]> {
        let url =`/Seller/InventorySource/TitleValue`
        return mapToDataOnly<SelectionView[]>(axios.get(url));
    }
}

export interface InventorySourceDTO {
    id : string,
    title: string,
    baseCurrencyBalance: number,
    description: string,
}

export interface ICreateInventorySourceDTO {
    title: string;
    description: string;
}

export interface IUpdateInventorySourceDTO extends ICreateInventorySourceDTO{
    id: string;
}
import axios from "axios";
import { ExchangeStatusEnum } from "../../components/financials/Enums/ExchangeStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";

export default class ExchangeService {

    get(filters: IExchangesPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IExchangeWalletTransactionRowDTO>> {
        return mapToDataOnly<GridResult<IExchangeWalletTransactionRowDTO>>(axios.get(`/Buyer/ExchangeTransaction${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getById(exchangeId: string): Promise<IExchangeWalletTransactionRowDTO> {
        return mapToDataOnly<IExchangeWalletTransactionRowDTO>(axios.get(`/Buyer/ExchangeTransaction/${exchangeId}`));
    }

    create(dto: ICreateRequestExchangeDTO) {
        return mapToDataOnly(axios.post(`/Buyer/ExchangeTransaction`, dto));
    }
}

interface ICreateRequestExchangeDTO
{
    fromWalletId: string,
    toWalletId: string,
    amount: number 
}

export interface IExchangesPaginationFilters{

}

interface IExchangeWalletTransactionRowDTO
{
    id: string,
    requestAt: Date,
    sourceCurrencyTitle : string,
    destinationCurrencyTitle: string,
    sourceAmount: number,
    destinationAmount: number,
    status: ExchangeStatusEnum,
}
import React from "react"
import { Bag, CurrencyDollar, PieChart, QuestionCircle, Speedometer, Stack, GearWideConnected, ThreeDotsVertical, Journals, CodeSlash, House, Shop } from "react-bootstrap-icons";
import './NavMenu.scss'
import { NavLink } from "react-router-dom";
import ShopAuthenticated from "../../../shared/ShopAuthenticated/ShopAuthenticated";
import { API_CONFIG_MENU, CURRENCIES_MENU, FINANCIAL_MENU, FULL_ACCESS, HELP_CENTER_MENU, INVENTORY_MENU, ORDER_MENU } from "../../../../constants/ConstantsPermissions";
import authenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import { Nav, Navbar } from "react-bootstrap";
import CoverFullScreen from "../../CoverFullScreen/CoverFullScreen";
import ShopPolicies from "../../../shared/policy/ShopPolicies/ShopPolicies";
import { SELLER, WEB_SERVICE } from "../../../../constants/ConstantsPolicies";

export interface IMenu {
    url: string,
    icon: any,
    label: string,
    permission: string | null
    policy?: string[]
}
export default class NavMenu extends React.Component<{}, { showNavbar: boolean, isMobile: boolean, currentRoute: string }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props)
        this.state = { isMobile: false, showNavbar: false, currentRoute: '' }
    }

    componentDidMount() {
        this.setState({ isMobile: window.innerWidth > 960 ? false : true, currentRoute: window.location.pathname })
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.state.currentRoute) this.setState({ currentRoute: window.location.pathname })
    }

    onToggle = () => {
        if (this.state.isMobile)
            this.setState({ showNavbar: !this.state.showNavbar })
        return
    }

    renderNavItem = (item, index) => {
        var active = this.state.currentRoute === item.url;


        return <div className={active ? 'active nav-link' : 'nav-link'} key={index} onClick={() => this.onToggle()}><NavLink to={item.url}> {item.icon}<span>{item.label}</span></NavLink></div >
    }

    render(): React.ReactNode {

        const items: IMenu[] = [
            { icon: <Shop />, label: 'Merketplace', url: '/marketplace?mode=express', permission: null },
            { icon: <Bag />, label: 'Orders', url: '/orders/buy', permission: ORDER_MENU },
            { icon: <Stack />, label: 'Inventory', url: '/inventory', permission: null, policy: [SELLER, WEB_SERVICE] },
            { icon: <GearWideConnected />, label: 'Management', url: '/management', permission: FULL_ACCESS },
            { icon: <CodeSlash />, label: 'API', url: '/rest-config', permission: API_CONFIG_MENU },
            { icon: <QuestionCircle />, label: 'Help Center', url: '/helpCenter', permission: HELP_CENTER_MENU },
        ]

        return (
            <>
                <Navbar className="NavMenu" bg="light" expand="lg" style={{ zIndex: '3' }} expanded={this.state.showNavbar} onToggle={() => this.onToggle()}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"><ThreeDotsVertical /></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {this.props.children}
                            {
                                items.map((item, index) => {
                                    var allowPolicy = false;

                                    if (item.policy)
                                        for (var policy of item.policy) {
                                            allowPolicy = this.context.policies?.includes(policy);
                                            if (allowPolicy)
                                                break;
                                        }
                                    return <>
                                        {
                                            (
                                                item.permission !== null ?
                                                    <ShopAuthenticated key={index} permission={item.permission as string}>
                                                        {
                                                            item.policy && item.policy.length > 0 ? (allowPolicy && this.renderNavItem(item, index)) : this.renderNavItem(item, index)
                                                        }
                                                    </ShopAuthenticated>
                                                    : item.policy && item.policy.length > 0 ? (allowPolicy && this.renderNavItem(item, index)) : this.renderNavItem(item, index)

                                            )
                                        }
                                    </>
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar >
                {this.state.isMobile && <CoverFullScreen onClick={() => this.onToggle()} isHide={!this.state.showNavbar} />}
            </>
        );
    }
}
import React from "react"
import { CurrencyDollar, ThreeDotsVertical, Speedometer2, Shop, People, Person, CardList, Gear} from "react-bootstrap-icons";
import './AdminNavMenu.scss'
import { NavLink } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import CoverFullScreen from "../../../CoverFullScreen/CoverFullScreen";
import { ADMIN, CONTENT_WRITER, MEDIATOR, SUPERADMIN, SUPPORT } from "../../../../../constants/ConstantsPermissions";
import AdminAuthenticated from "../../../../shared/AdminAuthenticated/AdminAuthenticated";

export interface IMenu {
    url: string,
    icon: any,
    label: string,
    permission: string | null
    policy?: string
    roles: string[]
}
export default class AdminNavMenu extends React.Component<{}, { showNavbar: boolean, isMobile: boolean, currentRoute: string }> {
    constructor(props: any) {
        super(props)
        this.state = { isMobile: false, showNavbar: false, currentRoute: '' }
    }

    componentDidMount() {
        this.setState({ isMobile: window.innerWidth > 960 ? false : true, currentRoute: window.location.pathname })
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.state.currentRoute) this.setState({ currentRoute: window.location.pathname })
    }

    onToggle = () => {
        if (this.state.isMobile)
            this.setState({ showNavbar: !this.state.showNavbar })
        return
    }

    render(): React.ReactNode {
        const items: IMenu[] = [
            { icon: <Speedometer2 />, label: 'Dashboard', url: 'admin/dashboard', permission: null, roles: [ADMIN, SUPERADMIN]},
            { icon: <CurrencyDollar />, label: 'Financials', url: 'admin/financials', permission: null, roles: [ADMIN, SUPERADMIN]},
            { icon: <Shop />, label: 'Shops', url: 'admin/shops', permission: null, roles: [ADMIN, SUPERADMIN] },
            { icon: <Shop />, label: 'Categories', url: 'admin/categories', permission: null, roles: [ADMIN, SUPERADMIN] },
            { icon: <Shop />, label: 'Products', url: 'admin/products', permission: null, roles: [ADMIN, SUPERADMIN] },
            { icon: <Person />, label: 'Users', url: 'admin/users', permission: null, roles: [ADMIN] },
            { icon: <People />, label: 'Support', url: 'admin/support-ticket', permission: null, roles: [ADMIN, SUPERADMIN, SUPPORT] },
            { icon: <People />, label: 'Review Request', url: 'admin/review-request', permission: null, roles: [ADMIN, SUPERADMIN, MEDIATOR] },
            { icon: <CardList />, label: 'Article', url: 'admin/article', permission: null, roles: [ADMIN, SUPERADMIN, CONTENT_WRITER] },
            { icon: <Gear />, label: 'Setting', url: 'admin/setting', permission: null, roles: [ADMIN, SUPERADMIN, CONTENT_WRITER] },
        ]

        return (
            <>
                <Navbar className="NavMenu" bg="light" expand="lg" style={{ zIndex: '3' }} expanded={this.state.showNavbar} onToggle={() => this.onToggle()}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"><ThreeDotsVertical /></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {this.props.children}
                            {
                                items.map((item, index) => {
                                    return (
                                        item.roles !== null ?
                                            <AdminAuthenticated roles={item.roles}>
                                            <div className={this.state.currentRoute === item.url ? 'active nav-link' : 'nav-link'} key={index} onClick={() => this.onToggle()}><NavLink to={item.url}> {item.icon}<span>{item.label}</span></NavLink></div>
                                            </AdminAuthenticated>
                                            :
                                            <div className={this.state.currentRoute === item.url ? 'active nav-link' : 'nav-link'} key={index} onClick={() => this.onToggle()}><NavLink to={item.url}> {item.icon}<span>{item.label}</span></NavLink></div>
                                    )
                                })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {this.state.isMobile && <CoverFullScreen onClick={() => this.onToggle()} isHide={!this.state.showNavbar} />}
            </>
        );
    }
}
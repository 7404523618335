import axios from "axios";
import { PackageStatusEnum } from "../../components/shared/policy/Enum/PackageStatusEnum";
import { mapToDataOnly } from "../common/HttpUtility";

export default class ShopPolicyService {
    sendRequest(policyKey: string): Promise<{}> {
        return mapToDataOnly<{}>(axios.post(`/Buyer/ShopPolicy/${policyKey}`));
    }

    checkShopPackage(policyKey: string): Promise<{ status?: PackageStatusEnum, createDate?: Date }> {
        return mapToDataOnly<{ status?: PackageStatusEnum, createDate?: Date }>(axios.get(`/Buyer/ShopPolicy/Status/${policyKey}`));
    }
}

import axios, { AxiosResponse } from "axios";
import { mapToDataOnly } from "../common/HttpUtility";
import IUpdateUserInformation from '../../models/dtos/identity/IUpdateUserInformationDTO'
import { IUserInformationDTO } from "../../models/dtos/identity/IUserInformationDTO";
import { IChangePasswordDTO } from "../../models/dtos/identity/IChangePasswordDTO";

export default class IdentityService {
    login(username: string, password: string, totp?: string | undefined): Promise<AxiosResponse<{ accessToken: string, refreshToken: string, hasTwoFactor: boolean, hasAccessToStore: boolean }, any>> {
        let uri = '/Identity/Account';
        return axios.post(uri, {
            userName: username,
            password: password,
            totp: totp
        });
    }
    forgotPassword(email: string, returnUrl: string): Promise<{}> {
        return mapToDataOnly(axios.post(`/Identity/Password/Forgot?returnURL=${returnUrl}`, { email: email }));
    }

    resetPassword(data: {
        token: string,
        newPassowrd: string,
        userId: string
    }, returnUrl: string): Promise<{}> {
        return mapToDataOnly(axios.post(`/Identity/Password/Forgot/Verify?returnURL=${returnUrl}`, data));
    }

    register(registerInformation: {
        password: string,
        email: string
        firstname: string
        lastname: string
    }, returnUrl: string): Promise<{}> {
        return mapToDataOnly(axios.post(`/Identity/Register/?returnURL=${returnUrl}`, registerInformation));
    }

    confirmationEmail(email: string, returnURL: string): Promise<{}> {
        return mapToDataOnly(axios.put(`/Identity/Security/ConfirmEmail`, { email, returnURL }));
    }

    getUserInformation(): Promise<AxiosResponse<{ displayName: string, hasTwoFactor: boolean, userName: string, roles: string[], privileges: string[] }, any>> {
        let uri = '/Identity/Account/Informations';
        return axios.get(uri);
    }

    getUserInformationComplete(): Promise<IUserInformationDTO> {
        let url = '/Identity/UserInformation';
        return mapToDataOnly<IUserInformationDTO>(axios.get(url));
    }

    updateUserInformation(userInformation: IUpdateUserInformation): Promise<AxiosResponse> {
        let url = '/Identity/UserInformation';
        return mapToDataOnly(axios.put(url, userInformation));
    }

    changePassword(data: IChangePasswordDTO): Promise<AxiosResponse> {
        let url = '/Identity/Password/Reset'
        return mapToDataOnly(axios.post(url, data));
    }

    getUserSecurityInformation(): Promise<ISecurityInformation> {
        let url = '/Identity/Security';
        return mapToDataOnly<ISecurityInformation>(axios.get(url));
    }

    generateTelegramBotToken(): Promise<string> {
        let url = 'Identity/SocialNetwork';
        return mapToDataOnly<string>(axios.get(url))
    }
}

interface ISecurityInformation {
    isTwoFactorEnabled: boolean
    isEmailConfirmed: boolean
}
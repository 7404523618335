import axios, { AxiosResponse } from "axios"
import { mapToDataOnly } from "../common/HttpUtility"

interface IUploaderDto {
    folderName: string;
    contentType: string;
    fullPath: string;
}

export default class CommonService {
    upload(file: FormData, folderName: string): Promise<IUploaderDto> {
        return mapToDataOnly<IUploaderDto>(axios.post(`FileManagement/${folderName}`, file, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        }));
    }

    download(id: string): Promise<any> {
        return axios.get(`/File/Download/${id}`, { responseType: 'blob' }).then(({ data }) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.png'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    export(url: string, fileName: string): Promise<void | AxiosResponse<any, any>> {
        return axios
            .get(`${url}`, { responseType: 'blob' })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }
}
import axios, { AxiosResponse } from "axios"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility"

let baseUrl = 'Admin/Article'

export default class AdminArticleService {
    getPaged(filters: IArticleFilter, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(axios.get(`${baseUrl}${mapQueryString({ ...paginationQuery, ...filters })}` ));
    }
    
    update(id: string, dto: IArticleDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.put(`${baseUrl}/${id}`, dto));
    }

    delete(id: string): Promise<AxiosResponse> {
        return mapToDataOnly(axios.delete(`${baseUrl}/${id}`));
    }

    add(dto: IArticleDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.post(`${baseUrl}`, dto));
    }

    getById(id: string): Promise<IArticleDTO> {
        return mapToDataOnly<IArticleDTO>(axios.get(`${baseUrl}/${id}`))
    }
}

interface IArticleFilter {
    published?: boolean,
    title?: string
}

export interface IArticleDTO {
    title: string;
    details: string;
    published: boolean;
    imageName: string;
    categoryId: string;
    imageSrc: string;
}
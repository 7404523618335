import axios, { AxiosResponse } from "axios";
import { mapToDataOnly } from "../common/HttpUtility";

export default class TwoFactorService {
    getToken(): Promise<string> {
        let url = '/Identity/TwoFactor';
        return mapToDataOnly<string>(axios.get(url));
    }
    
    verify(totp: string): Promise<IVerificationTwoFactorDTO> {
        let url = '/Identity/TwoFactor/Enable';
        return mapToDataOnly(axios.put(url, {}, {headers: {totp}}));
    }

    disable(totp: string): Promise<{}> {
        let url = '/Identity/TwoFactor/Disable';
        return mapToDataOnly(axios.put(url, {}, {headers: {totp}}));
    }
    
    signInWithRecoveryCode(dto: IRecoveryTwoFactorDTO, totpRecovery: string): Promise<IAccessTokenDTO> {
        let url = '/Identity/TwoFactorRecovery/SignIn';
        return mapToDataOnly<IAccessTokenDTO>(axios.put(url, dto , {headers: {'totp-recovery': totpRecovery}}));
    }

    disableWithRecoveryCode(totpRecovery: string): Promise<{}> {
        let url = '/Identity/TwoFactorRecovery/Disable';
        return mapToDataOnly(axios.put(url, null , {headers: {'totp-recovery': totpRecovery}}));
    }
}

interface IVerificationTwoFactorDTO {
    isVerified: boolean
    recoveryCodes: string[]
}

interface IRecoveryTwoFactorDTO {
    email: string,
    password: string,
    disableTwoFactor: boolean
}

interface IAccessTokenDTO {
    accessToken: string,
    refreshToken: string,
    expirationDate: Date,
    refreshExpirationDate: Date,
    hasTwoFactor: boolean
}
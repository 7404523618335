import axios from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { InventoryAutoBasePriceMethodEnum } from "../../components/inventory/Enums/InventoryAutoBasePriceMethodEnum";
import { InventoryDeliveryDelayEnum } from "../../components/inventory/Enums/InventoryDeliveryDelayEnum";
import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum";
import { InventoryShopPlanDeliveryEnum } from "../../components/inventory/Enums/InventoryShopPlanDeliveryEnum";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";

export default class InventoryProductOverviewService {

    getOrderingPlan(productId: string): Promise<IConfig> {
        let url = `/Seller/OrderingPlan/${productId}`
        return mapToDataOnly<IConfig>(axios.get(url));
    }

    customCount(productId: string): Promise<number> {
        let url = `/Seller/InventoryProduct/CustomCount/${productId}`
        return mapToDataOnly<number>(axios.get(url));
    }

    getDeficits(productId: string): Promise<IDeficitDTO[]> {
        let url = `/Seller/InventoryShopCollection/Deficits/${productId}`
        return mapToDataOnly<IDeficitDTO[]>(axios.get(url));
    }

    getUniqueBuyOrderNumber(queryString: IGetUniqueBuyOrderNumberQueryString): Promise<IUniqueSellerOrderNumberDTO> {
        let url = `/Seller/InventoryShopCollection/OrderNumber?productId=${queryString.productId}&sourceId=${queryString.sourceId}&orderNumber=${queryString.orderNumber}`
        return mapToDataOnly<IUniqueSellerOrderNumberDTO>(axios.get(url));
    }

    getProductStructureTitleValues(productId: string): Promise<SelectionView[]> {
        let url = `/Seller/Product/Structure/TitleValue/${productId}`
        return mapToDataOnly<SelectionView[]>(axios.get(url));
    }

    updateProductInventoryConfig(dto: IUpdateProductInventoryConfigDTO): Promise<any> {
        return mapToDataOnly(axios.post(`/Seller/OrderingPlan`, dto));
    }

    updateInventoryConfigStatus(productId: string, dto: { status: InventoryShopBasePlanStatusEnum }): Promise<any> {
        return mapToDataOnly(axios.put(`/Seller/InventoryProduct/Status/${productId}`, dto));
    }
}

export interface IConfig {
    deliveryConfig: IConfigDeliveryDTO
    inventoryConfig: InventoryConfig
    isPro: boolean
    plansConfig?: IPlansConfig
    pricingConfig: IPricingConfig
    systemDetails: ISystemDetails
}

export interface ISystemDetails {
    sharePercent: number
    productId: string
    productTitle: string
    shopId: string
    isMainCurrency: boolean
    status: InventoryShopBasePlanStatusEnum | null
}

export interface IPricingConfig {
    baseCustomPrice: string
    isAutoBasePrice: boolean
    lastAutoBasePriceAmount: number
    lastAveragePrice: number
    minimumBasePrice: string
}

export interface IPlansConfig {
    pricingStatics: IPricingStatics[]
}

export interface IPricingStatics {
    isActive?: boolean;
    countFrom?: number;
    countTo?: number;
    profitPercent?: string;
}

export interface InventoryConfig {
    isActiveMinimumAlert: boolean
    isActiveMinimumCalculated: boolean
    minimumAlertCustomCount: number
    averagePrice: number
    averagePriceWithCurrencyRate: number

}

export interface IConfigDeliveryDTO {
    delayFromNumber?: number;
    delayToNumber?: number;
    delayType?: InventoryDeliveryDelayEnum;
    enableParticipateOffers?: boolean;
}

interface IGetUniqueBuyOrderNumberQueryString {
    productId: string;
    sourceId: string;
    orderNumber: string;
}

interface IUniqueSellerOrderNumberDTO {
    buyPrice: number;
    orderDate: string;
    sourceId: string;
}

interface IShopInventoryOverviewDTO {
    productId: string;
    title: string;
    orginalPrice: number;
    soldCount: number;
    realPrice: number;
    availableCount: number;
    status: InventoryShopBasePlanStatusEnum | null;
}

export interface IUpdateProductInventoryConfigDTO {
    minimumAlert: IConfigMinimumAlertDTO;
    baseCustomPrice: number;
    minimumBasePrice: number | null;
    isAutoBasePrice: boolean;
    productId: string;
    plansConfig: {
        plans: IPricingStatics[],
    };
    delivery: IConfigDeliveryDTO;
    isPro: boolean;
}

export interface IConfigMinimumAlertDTO {
    isActive?: boolean;
    isAutoCalculatedCount?: boolean;
    customCount?: number;
}

export interface IDeficitDTO {
    sourceTitle: string;
    sourceId: string;
    orderNumber: string;
    count: number;
}
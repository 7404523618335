import axios from "axios";
import { IAdminWithdrawalsFilters } from "../../../components/admin/financials/AdminWithdrawals/AdminWithdrawals.models";
import { CashOutStatusEnum } from "../../../components/financials/Enums/CashOutStatusEnum";
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";

interface IAdminWithdrawRowDto {
    id: string
    amount: number
    handleAt: string | null
    requestAt: string
    status: CashOutStatusEnum
    shopTitle: string
    shopId: string
    isBlockchainVerification: boolean | null
}

interface IAdminWithdrawDetailsDto {
    id: string
    amount: number
    handleAt: string | null
    requestAt: string
    status: CashOutStatusEnum
    shopTitle: string
    shopId: string
    description: string,
    transactionNumber: string,
    financialAccountType: string,
    financialAccount: string,
    handlerUserId: string,
    handlerName: string,
}

export default class AdminWithdrawalsService {
    get(query: IAdminWithdrawalsFilters, paginationQuery: PaginationQuery): Promise<GridResult<IAdminWithdrawRowDto>> {
        return mapToDataOnly<any>(axios.get(`Admin/Withdraw?pageskip=${paginationQuery.pageskip - 1}&pagesize=${paginationQuery.pagesize}` + (query.status === undefined ? '' : `&status=${query.status}`) + (query.unreviewedOnly === true ? '&unreviewedOnly=true' : '')));
    }

    reject(withdrawId: string): Promise<void> {
        return mapToDataOnly<any>(axios.put(`Admin/Withdraw/Reject/${withdrawId}`));
    }

    accept(withdrawId: string): Promise<void> {
        return mapToDataOnly<any>(axios.put(`Admin/Withdraw/Accept/${withdrawId}`));
    }

    review(withdrawId: string): Promise<void> {
        return mapToDataOnly<any>(axios.put(`Admin/Withdraw/Review/${withdrawId}`));
    }

    done(withdrawId: string, transactionNumber: string): Promise<void> {
        return mapToDataOnly<any>(axios.put(`Admin/Withdraw/Done/${withdrawId}`, { transactionNumber: transactionNumber }));
    }

    getById(id: string): Promise<IAdminWithdrawDetailsDto> {
        return mapToDataOnly<any>(axios.get(`Admin/Cashout/${id}`));
    }
}
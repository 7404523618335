import axios from "axios"
import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { SelectionView } from "../../common/Models"

let baseUrl = 'Admin/Product'

export interface IProductRowDTO {
    title: string
    isActive: boolean
    isPrivate: boolean
    categoryId: string
    structures: ProductStructureDTO[]
    shortTitle: string
    currencyId: string
    realPrice: number
    imageName: string
    imageSrc?: string
}

interface  ProductStructureDTO {
    title: string
    key: string
    isUniqueValue: boolean
}

export default class AdminProductService {
    getPaged(filters: any, paginationQuery: PaginationQuery): Promise<GridResult<IProductRowDTO>> {
        return mapToDataOnly<GridResult<IProductRowDTO>>(axios.get(`${baseUrl}?pageskip=${paginationQuery.pageskip - 1}&pagesize=${paginationQuery.pagesize}`))
    }

    getById(id: string): Promise<IProductRowDTO> {
        return mapToDataOnly<IProductRowDTO>(axios.get(`${baseUrl}/${id}`))
    }

    update(id: string, dto: IProductRowDTO): Promise<void> {
        return mapToDataOnly<any>(axios.put(`${baseUrl}/${id}`, dto))
    }

    add(dto: IProductRowDTO): Promise<void> {
        return mapToDataOnly<any>(axios.post(`${baseUrl}`, dto))
    }

    delete(id: string): Promise<void> {
        return mapToDataOnly<any>(axios.delete(`${baseUrl}/${id}`))
    }

    getCurrency(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`Buyer/Currency/TitleValue`))
    }
}
import axios from "axios";
import { CashPaymentStatusEnum } from "../../../components/financials/Enums/CashPaymentStatusEnum";
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../../common/HttpUtility";

interface IAdminDepositRowDto {
    id: string
    amount: number
    handleAt: string | null
    crreateAt: string
    status: CashPaymentStatusEnum
    isBlockchainVerification: boolean
    shopTitle: string
    shopId: string
    transactionNumber: string
    cashPaymentAccountTitle: string
}

interface IAdminDepositDetailsDto {
    // id: string
    // amount: number
    // handleAt: string | null
    // requestAt: string
    // status: CashOutStatusEnum
    // shopTitle: string
    // shopId: string
    // description: string,
    // transactionNumber: string,
    // financialAccountType: string,
    // financialAccount: string,
    // handlerUserId: string,
    // handlerName: string,
}

export interface HandleCashPaymentDTO {
    cashPaymentId: string;
    isAccepted: boolean;
    handleDescription: string;
    withTransaction: boolean;
}

interface IAdminDepositQueryString extends PaginationQuery {
    status?: CashPaymentStatusEnum;
    unreviewedOnly?: boolean
}

export default class AdminDepositsService {
    getPaged(query: IAdminDepositQueryString): Promise<GridResult<IAdminDepositRowDto>> {
        return mapToDataOnly<any>(axios.get(`Admin/CashPayment?pageskip=${query.pageskip - 1}&pagesize=${query.pagesize}`
            + (query.status === undefined ? '' : `&status=${query.status}`) + (query.unreviewedOnly === true ? '&unreviewedOnly=true' : '')));
    }

    getById(id: string): Promise<IAdminDepositDetailsDto> {
        return mapToDataOnly<any>(axios.get(`Admin/CashPayment/${id}`));
    }


    review(depositId: string): Promise<void> {
        return mapToDataOnly<any>(axios.put(`Admin/CashPayment/Review/${depositId}`));
    }

    reject(depositId: string): Promise<void> {
        return mapToDataOnly<any>(axios.put(`Admin/Deposit/Reject/${depositId}`,));
    }

    accept(dto: AcceptDepositDto): Promise<void> {
        return mapToDataOnly<any>(axios.put(`Admin/Deposit/Accept`, dto));
    }
}

interface AcceptDepositDto {
    id: string
}
import axios from "axios";
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility";

export default class AdminOrderProductService {
    getStatistics(onlyShared: boolean, fromDate?: string, toDate?: string, timePeriod?: TimePeriod): Promise<any> {
        if (onlyShared)
            return mapToDataOnly<any>(axios.get(`/Admin/OrderProduct/SharePrice/Statistics${mapQueryString({ fromDate, toDate, timePeriod })}`));
        else
            return mapToDataOnly<any>(axios.get(`/Admin/OrderProduct/Statistics${mapQueryString({ fromDate, toDate, timePeriod })}`));
    }

    getReport(): Promise<SalesReport> {
        return mapToDataOnly<SalesReport>(axios.get(`/Admin/OrderProduct/Report`));
    }
}

export interface SalesReport {
    profitPrice: number
    totalPrice: number
}

export enum TimePeriod {
    Yearly = 0,
    Monthly = 1,
    Daily = 2,
    Weekly = 3
}
import axios, { AxiosResponse } from "axios";
import WalletListItem from "../../components/financials/wallets/WalletList/WalletList.models";
import WalletTransactionListItem from "../../components/financials/wallets/WalletTransactionList/WalletTransactionList.models";
import { IWalletTransactionsFilters } from "../../components/financials/wallets/WalletTransactionReport/WalletTransactionReport.models";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import CommonService from "../common/CommonService";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";

export default class WalletService {
    getOverview(): Promise<IWalletBalanceDTO> {
        return mapToDataOnly<IWalletBalanceDTO>(axios.get('/Buyer/Wallet/Balance'));
    }
    getWalletList(filters: IWalletsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<WalletListItem>> {
        return mapToDataOnly<GridResult<WalletListItem>>(axios.get(`Buyer/Wallet${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getWalletTransactionList(query: WalletTransactionQuery): Promise<GridResult<WalletTransactionListItem>> {
        return mapToDataOnly<GridResult<WalletTransactionListItem>>(axios.get(`/Buyer/WalletTransaction?walletid=${query.walletId}&pageskip=${query.pageskip - 1}&pagesize=${query.pagesize}`));
    }

    getBalanceTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`Buyer/Wallet/BalanceTitleValues`));
    }

    getLastAverage(currencyId: string): Promise<{ lastAverage: number }> {
        return mapToDataOnly<{ lastAverage: number }>(axios.get(`LocalCurrency/LastAverage/${currencyId}`));
    }

    createPayment(dto: CreatePaymentDto): Promise<string> {
        return mapToDataOnly<string>(axios.post(`Buyer/CashPayment`, dto));
    }

    getPaymentMethods(currencyId: string): Promise<PaymentMethodDto[]> {
        return mapToDataOnly<PaymentMethodDto[]>(axios.get(`Buyer/CashPaymentAccount/Selections/${currencyId}`));
    }

    getBalance(walletId: string): Promise<IWalletBalanceDTO> {
        return mapToDataOnly<IWalletBalanceDTO>(axios.get(`Seller/Wallet/Balance/${walletId}`));
    }


    transferFund(dto: WalletTransferFund): Promise<WalletTransferFundRespose> {
        return mapToDataOnly<WalletTransferFundRespose>(axios.post(`MarketPlace/WalletTransaction`, dto));
    }

    getWalletTransactions(filters: IWalletTransactionsFilters, paginationQuery: PaginationQuery): Promise<GridResult<WalletTransactionListItem>> {
        let url = `/Buyer/WalletTransactionReport${mapQueryString({ ...filters, ...paginationQuery })}`
        return mapToDataOnly<GridResult<WalletTransactionListItem>>(axios.get(url));
    }

    downloadExcelWalletTransactions(filters: IWalletTransactionsFilters): Promise<void | AxiosResponse<any, any>> {
        var commonService = new CommonService();
        let url = `/Buyer/WalletTransactionReport/Excel${mapQueryString({ ...filters, pageskip: 1, pagesize: 1 })}`
        return commonService.export(url, 'wallet-transactions.xlsx')
    }

    getFavoriteDestinations(): Promise<{ result: FavoriteTransferDestinationRow[] }> {
        return mapToDataOnly<{ result: FavoriteTransferDestinationRow[] }>(axios.get('MarketPlace/WalletTransaction/FavoriteTransferDestinations'));
    }
}

export interface FavoriteTransferDestinationRow {
    address: string
    shopName: string
    id: string
    createAt: string
}

interface WalletTransferFund {
    amount: number,
    description: string,
    sourceWalletId: string,
    destinationWalletId: string,
    addDestinationToFavorites: boolean
}


interface WalletTransferFundRespose {
    transactionId: string,
}

export interface IWalletsPaginationFilters {

}

export interface IWalletBalanceDTO {
    balance: number;
    blockedBalance: number;
    withdrawCredit: number;
    pendingBalance: number;
    credit: number;
    localCurrencyAverage: number;
}

export interface PaymentMethodDto {
    accountNumber: string
    description: string
    id: string
    title: string,
    providerKey: string
}

interface CreatePaymentDto {
    amount: number
    autoExchangeRequestWalletId: string
    cashPaymentAccountId: string
    description: string
    purchasePrice: number
    transactionAt: string
    // transactionAt: "2022-05-02T14:23:54.714+04:30"
}

export interface WalletTransactionQuery extends PaginationQuery {
    walletId: string;
}
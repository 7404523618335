import axios from "axios";
import { GetPagedEarthSupportTicketsQueryString } from '../../../components/admin/support-tickets/TicketList/AdminTicketsList.models';
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { ISupportTicketDetailsCommentDTO } from "../../../components/helpCenter/Reports/Details/ReportDetails.models";
import { SupportTicketStatus } from "../../../components/helpCenter/SupportTickets/SupportTicketStatusEnum";
import { mapToDataOnly, mapQueryString } from "../../common/HttpUtility";
import { SelectionView } from "../../common/Models";


interface SupportTicketRowDTO {
    id: string;
    title: string;
    from: string;
    handlerName: string;
    modifyAt: string;
    status: SupportTicketStatus;
    isGood: boolean | null;
    ticketNumber: string;
}

interface CreateSupportTicketReplyDTO {
    sellerTarget?: boolean;
    replyText: string;
    attachmentNames?: string[]
    supportTicketId: string;
}

interface CreateSupportTicketCommentDTO {
    commentText: string;
    supportTicketId: string;
}

interface CreateGlobalSupportTicketDTO {
    title: string;
    ticketText: string;
    shopId: string;
    departmentId: string;
}

interface SupportTicketDTO {
    title: string
    productTitle: string
    sellPrice: number
    ticketNumber: string
    createAt: string
    modifyAt: string
    toId: string
    to: string
    from: string
    fromId: string
    inventoryInformation: string
    inventoryEnterAt: string
    viewByBuyerAt: string
    handlerName: string
    department?: SelectionView
    status: SupportTicketStatus
    isGood?: boolean | null
    comments: ISupportTicketDetailsCommentDTO[]
    replies?: ISupportTicketDetailsCommentDTO[]
    mediationRequestDate?: Date
    canRequestMediation: boolean,
    isMediationDone?: boolean,
    isProductRefunded: boolean,
    orderProductId: string
    cancelable: boolean,
    tags: SelectionView[],
}

export default class AdminSupportTicketsService {
    getPaged(filters: GetPagedEarthSupportTicketsQueryString, paginationQuery: PaginationQuery): Promise<GridResult<SupportTicketRowDTO>> {
        return mapToDataOnly<any>(axios.get(`Admin/SupportTicket${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getSupportTicketArchive(filters: GetPagedEarthSupportTicketsQueryString, paginationQuery: PaginationQuery): Promise<GridResult<SupportTicketRowDTO>> {
        return mapToDataOnly<any>(axios.get(`Admin/SupportTicketArchive${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getById(id: string): Promise<SupportTicketDTO> {
        return mapToDataOnly<any>(axios.get(`Admin/SupportTicket/${id}`));
    }

    create(globalTicket: CreateGlobalSupportTicketDTO): Promise<SupportTicketDTO> {
        return mapToDataOnly<any>(axios.post(`Admin/SupportTicket/Global`, globalTicket));
    }

    updateStatus(id: string, status: SupportTicketStatus): Promise<SupportTicketDTO> {
        return mapToDataOnly<any>(axios.put(`Admin/SupportTicket/${id}/${status}`));
    }

    createReply(dto: CreateSupportTicketReplyDTO): Promise<SupportTicketDTO> {
        return mapToDataOnly<any>(axios.post(`Admin/SupportTicket/Reply`, dto));
    }

    createComment(dto: CreateSupportTicketCommentDTO): Promise<SupportTicketDTO> {
        return mapToDataOnly<any>(axios.post(`Admin/SupportTicket/Comment`, dto));
    }
}
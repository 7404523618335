import { AxiosError, AxiosResponse } from "axios";

export function mapToDataOnly<T>(request: Promise<AxiosResponse>): Promise<T> {

    return new Promise<T>((resolve, reject) => {
        request.then((response) => resolve(response.data)).catch((reason) => reject(reason));
    });
}

export function mapOnly<AxiosResponse>(request: Promise<AxiosResponse>): Promise<AxiosResponse> {

    return new Promise<AxiosResponse>((resolve, reject) => {
        request.then((response) => resolve(response)).catch((reason) => reject(reason));
    });
}

export function mapQueryString(querystring: any) {
    var result = "";
    Object.entries(querystring).map(([key, value], index) => {
        var symbol = index === 0 ? "?" : "&";
        value = key === "pageskip" ? Number.parseInt(value.toString()) - 1 : value;

        if (value instanceof Date) {
            value = value.toISOString();
        }

        result += (value !== null && value !== undefined && value !== "") ? symbol + key + "=" + value : "";

        if (!result.includes("?"))
            result += "?";
    })

    return result;
}

import axios from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";

export default class HoldService {
    get(filters: {walletId: string}, paginationQuery: PaginationQuery): Promise<GridResult<HoldRowDTO>> {
        return mapToDataOnly<GridResult<HoldRowDTO>>(axios.get(`/MarketPlace/Hold${mapQueryString({ ...filters, ...paginationQuery })}`));
    }
}

export interface HoldRowDTO {
    id: string;
    ownerId: string | null;
    ownerTitle: string | null;
    amountValue: number;
    createDate: string;
    creatorUserId: string;
    description: string;
    creatorUserName: string;
}
import axios from "axios";
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly,mapQueryString } from "../../common/HttpUtility";

interface SupportTicketCartableRowDTO {
    id: string
    subject: string
    description: string
    createAt: string
}

export default class AdminReviewRequestService {
    getCartablePaged(filters: {}, paginationQuery: PaginationQuery): Promise<GridResult<SupportTicketCartableRowDTO>> {
        return mapToDataOnly<any>(axios.get(`Admin/SupportTicketCartable${mapQueryString({ ...filters, ...paginationQuery })}` ));
    }

    review(id: string): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Admin/SupportTicketCartable/Review/${id}` ));
    }
}
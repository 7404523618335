import axios, { AxiosRequestConfig } from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";

export default class ShopService {

    getShops(query: ShopListPagination): Promise<GridResult<ShopRowDto>> {
        return mapToDataOnly<GridResult<ShopRowDto>>(axios.get(`/Seller/Shop${mapQueryString(query)}`));
    }

    getTitles(requestConfig?: AxiosRequestConfig): Promise<{ title: string, value: string }[]> {
        return mapToDataOnly<any>(axios.get('/MarketPlace/Shop/TitleValue', requestConfig));
    }

    getOverview(): Promise<{ policies: string[], isOwner: boolean, isSuspended: boolean }> {
        return mapToDataOnly<any>(axios.get('/MarketPlace/Shop/Overview'));
    }

    getCurrent(): Promise<UpdateShopDTO> {
        return mapToDataOnly<UpdateShopDTO>(axios.get(`/MarketPlace/Shop/Current`));
    }

    create(shop: CreateShopDto): Promise<{}> {
        return mapToDataOnly<{}>(axios.post('/Buyer/Shop/', shop));
    }

    update(shop: UpdateShopDTO): Promise<{}> {
        return mapToDataOnly<{}>(axios.put('/Buyer/Shop/', shop));
    }

    getLocalCurrency(): Promise<ShopLocalCurrencyDto> {
        return mapToDataOnly<ShopLocalCurrencyDto>(axios.get(`Buyer/Shop/LocalCurrency`));
    }

    getShopInfo(walletId: string): Promise<{ title: string, value: string }> {
        return mapToDataOnly<{ title: string, value: string }>(axios.get(`MarketPlace/Shop/ShopInfo/${walletId}`));
    }
}

export interface ShopListPagination extends PaginationQuery {
    status?: number;
}

interface ShopLocalCurrencyDto {
    currencyKey: string
    currencySymbol: string
    currencyTitle: string
    localCurrencyId: string
}


interface CreateShopDto {
    address?: string
    email: string
    siteAddreess?: string
    tell?: string
    title: string
}


interface ShopRowDto {
    activeAt: string
    createAt: string
    id: string
    memberCount: number
    number: string
    status: number
    title: string
}

export interface UpdateShopDTO {
    title: string
    siteAddreess: string
    email: string
    tell: string
    address: string
    shopId: string
}
import axios from "axios"
import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility"
import { UserStatusEnum } from "../../../components/identity/Enums/UserStatusEnum"

let baseUrl = 'Admin/User'

export default class AdminUserService {
    getPaged(filters: IUserFilters, paginationQuery: PaginationQuery): Promise<GridResult<IUserRowDTO>> {
        return mapToDataOnly<GridResult<IUserRowDTO>>(axios.get(`${baseUrl}${mapQueryString({ ...paginationQuery, ...filters })}`))
    }

    getUserRoles(userId: string): Promise<UserRoleView[]> {
        return mapToDataOnly<UserRoleView[]>(axios.get(`${baseUrl}/Roles/${userId}`))
    }

    editUserRoles(userId: string, userRoleKeys: string[]): Promise<any> {
        return mapToDataOnly<any>(axios.put(`${baseUrl}/Roles/${userId}`, userRoleKeys))
    }
}

interface UserRoleView {
    id: string;
    title: string;
    key: string;
    hasRole: boolean;
}

interface IUserFilters {
    id?: string
    name?: string
    email?: string
}

interface IUserRowDTO {
    id: string;
    displayName: string;
    userName: string;
    email: string;
    registerDate: string;
    status: UserStatusEnum;
    hasTwoFactor: boolean;
}
import axios from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";
import { InventoryShopCollectionFilters } from "../../components/inventory/InventoryShopCollection/InventoryShopCollectionList";

export default class InventoryShopCollectionService {
    getPaged(filters: InventoryShopCollectionFilters, paginationQuery: PaginationQuery): Promise<GridResult<InventoryShopCollectionRowDTO>> {
        return mapToDataOnly<GridResult<InventoryShopCollectionRowDTO>>(axios.get(`/Seller/InventoryShopCollection${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    updateDeficitCount(dto: UpdateDeficitDTO): Promise<any> {
        return mapToDataOnly<any>(axios.put(`/Seller/InventoryShopCollection/ChangeDeficit`, dto));
    }

    getDeficits(filters: IDeficitsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<InventoryShopCollectionRowDTO>> {
        let url = `/Seller/InventoryShopCollection/Deficits${mapQueryString({ ...filters, ...paginationQuery })}`
        return mapToDataOnly<any>(axios.get(url));
    }

    getDeficitCount(): Promise<number> {
        let url = `/Seller/InventoryShopCollection/Deficits/Count`
        return mapToDataOnly<any>(axios.get(url));
    }
}

export interface IDeficitsPaginationFilters {
    productId?: string
}

export interface IDeficitRowDTO {
    productId: string;
    title: string;
    count: number;
    sourceTitle: string;
    orderNumber: string;
    date: string;
}

interface InventoryShopCollectionRowDTO {
    id: string;
    buyAt: string;
    productTitle: string;
    sourceTitle: string;
    price: number;
    count: number;
    buyOrderNumber: string;
    deficitCount: number;
}

interface UpdateDeficitDTO {
    shopCollectionId: string;
    deficitCount: number;
}
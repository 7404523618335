import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility";


export default class BuyPlanService {

    get(productId: string): Promise<BuyPlanDto> {
        return mapToDataOnly<any>(axios.get(`BuyPlan/${productId}`));
    }

    update(dto: UpdateBuyPlanDto): Promise<any> {
        return mapToDataOnly<any>(axios.put(`/BuyPlan`, dto));
    }
}




export interface UpdateBuyPlanDto {
    productId: string;
    customLimitPolicy: boolean;
    allowAllSellers: boolean;
    allowedSellerShopIds: string[];
    denySellerShopIds: string[];
    maxPrice: number;
    minimumCount: number;
    maximumCount: number;
    plannedDayCount: number;
    status: BuyPlanStatus;
}
export interface BuyPlanDto {
    productId: string;
    customLimitPolicy: boolean;
    allowAllSellers: boolean;
    allowedSellerShopIds: string[];
    denySellerShopIds: string[];
    maxPrice: number;
    minimumCount: number;
    maximumCount: number;
    plannedDayCount: number;
    status: BuyPlanStatus;
    outDailyAverage: number;
}

export enum BuyPlanStatus {
    PendingOnly,
    AutoMinimum,
    CustomMinimum
}
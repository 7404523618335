import axios from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { IShopReportSupportTicketQueryString } from "../../components/helpCenter/Reports/ReportsList/ReportsList.models";
import { IShopInventoryTicketDTO } from "../../components/inventory/InventoriesList/InventoryList.models";
import { mapOnly, mapQueryString, mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";

export default class SupportTicketsService {

    getGlobalTicket(query: IShopReportSupportTicketQueryString): Promise<GridResult<{
        createAt: string
        id: string
        memberDisplayName: string
        modifyAt: string
        status: number
        title: string,
        tags: string[]
    }>> {
        return mapToDataOnly<GridResult<any>>(axios.get(`SupportTickets${mapQueryString(query)}`));
    }

    getById(id: string): Promise<SupportTicketDetailsDto> {
        return mapToDataOnly<SupportTicketDetailsDto>(axios.get(`SupportTickets/${id}`));
    }

    getDepartments(): Promise<DepartmentSelectionDTO[]> {
        return mapToDataOnly<DepartmentSelectionDTO[]>(axios.get(`SupportTickets/Departments`));
    }

    reply(supportTicketId: string, text: string, attachmentNames?: string[]): Promise<any> {
        return mapToDataOnly<any>(axios.put<any, any>(`SupportTickets/${supportTicketId}`, { replyText: text, attachmentNames }));
    }

    create(title: string, text: string, departmentId: string, attachmentNames?: string[]): Promise<any> {
        return mapToDataOnly<any>(axios.post<any, any, CreateDto>(`SupportTickets`, {
            departmentId: departmentId,
            ticketText: text,
            title: title,
            attachmentNames
        }));
    }
    createComment(supportTicketId: string, text: string): Promise<any> {
        return mapToDataOnly<any>(axios.post(`/SupportTickets/${supportTicketId}/Comment`, { commentText: text }));
    }
    addVote(dto: IUpdateSupportTicketVoteDTO): Promise<any> {
        return mapToDataOnly<any>(axios.put(`SupportTickets/Vote`, dto))
    }
}

interface IUpdateSupportTicketVoteDTO {
    isGood: boolean;
    supportTicketId: string;
}

interface CreateDto {
    departmentId: string,
    ticketText: string,
    title: string
    attachmentNames?: string[]
}

interface DepartmentSelectionDTO {
    value: string,
    title: string
}

interface CreateReplyDto {
    replyText: string
    supportTicketId: string
    attachmentNames?: string[]
}

interface SupportTicketDetailsDto {
    sellerId: string,
    returnReasonCode: 'INVALID_CODE' | 'DIFFERENT_VALUE' | 'ALREADY_REDEEMED' | 'DIFFERENT_REGION'
    createAt: Date
    department: {
        title: string
        value: string
    },
    handlerName: string
    isGood: boolean | null
    modifyAt: string
    replies: {
        createAt: string
        description: string
        userDisplayName: string
        shopId: string | null
    }[]
    status: number
    ticketNumber: string,
    title: string,
    orderProductId?: string | null,
    productTitle?: string,
    sellPrice?: number,
    inventory?: IShopInventoryTicketDTO,
    mediationRequestDate?: Date,
    canRequestMediation: boolean,
    isMediationDone?: boolean,
    tags: SelectionView[]
}
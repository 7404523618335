import axios from "axios"
import { IWithdrawalsPaginationFilters } from "../../components/financials/cashOutes/CashOutsList/CashOutsList.models";
import { CashOutStatusEnum } from "../../components/financials/Enums/CashOutStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models"
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility"

export default class CashOutsService {
    getPaged(filters: IWithdrawalsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IShopCashOutRowDTO>> {
        return mapToDataOnly<GridResult<IShopCashOutRowDTO>>(axios.get(`/Marketplace/Withdraw${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    create(dto: IAddCashOutDTO): Promise<{}> {
        return mapToDataOnly<{}>(axios.post(`/Marketplace/Withdraw`, dto));
    }

    handle(dto: IHandleCashOutDTO): Promise<{}> {
        return mapToDataOnly<{}>(axios.post(`/Seller/Cashout/Handle`, dto));
    }

    cancel(id:string): Promise<{}> {
        return mapToDataOnly<{}>(axios.put(`/Marketplace/Withdraw/Cancel/${id}`));
    }
    
    getFee(): Promise<WithdrawFeeDTO> {
        return mapToDataOnly<WithdrawFeeDTO>(axios.get(`/Marketplace/Withdraw/Fee`));
    }
}

interface IShopCashOutRowDTO {
    id: string
    amount: number
    financialAccount: string
    sendAt: string | null
    requestAt: string
    status: CashOutStatusEnum
}

interface WithdrawFeeDTO {
    value: number
}

interface IAddCashOutDTO {
    financialAccountId: string;
    amount: number;
    description: string;
    inventorySourceId: string | null;
}

interface IHandleCashOutDTO {
    isForceCashout: boolean;
    cashoutId: string;
    isAccepted: boolean;
    description: string;
}
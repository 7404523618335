import axios, { AxiosResponse } from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { ISourceTransactionFilters } from "../../components/inventory/InventorySourceTransaction/InventorySourceTransactionList/InventorySourceTransactionList.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";

export default class InventorySourceTransactionService {
    getPaged(queryString: ISourceTransactionFilters): Promise<GridResult<ISourceTransactionDTO>> {
        let url = `/Seller/InventorySourceTransaction?currencyId=${queryString.currencyId}&inventorySourceId=${queryString.inventorySourceId}&pageskip=${queryString.pageskip - 1}&pagesize=${queryString.pagesize}`
        return mapToDataOnly<GridResult<ISourceTransactionDTO>>(axios.get(url));
    }

    getById(id: string): Promise<IUpdateSourceTransactionDTO> {
        let url =`/Seller/InventorySourceTransaction/${id}`
        return mapToDataOnly<IUpdateSourceTransactionDTO>(axios.get(url));
    }

    getTitleValue(id: string): Promise<SelectionView[]> {
        let url =`/Seller/InventorySourceTransaction/Currencies/TitleValue/${id}`
        return mapToDataOnly<SelectionView[]>(axios.get(url));
    }
    
    create(dto: ICreateSourceTransactionDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.post(`/Seller/InventorySourceTransaction`, dto));
    }

    update(dto: IUpdateSourceTransactionDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.put(`/Seller/InventorySourceTransaction`, dto));
    }

    delete(id: string): Promise<AxiosResponse> {
        return mapToDataOnly(axios.delete(`/Seller/InventorySourceTransaction/${id}`));
    }
}

export interface ISourceTransactionDTO {
    id: string;
    amount: number;
    transactionAt: string;
    isToSource: boolean;
    description: string;
}

export interface IUpdateSourceTransactionDTO {
    inventorySourceTransactionId: string;
    sourceAmountValue: number;
    shopAmountValue: number;
    description: string;
    transactionAt: Date;
}

export interface ICreateSourceTransactionDTO {
    shopCurrencyId: string;
    sourceCurrencyId: string;
    inventorySourceId: string;
    sourceAmountValue: number;
    shopAmountValue: number;
    description: string;
    transactionAt: Date;
    isToSource: boolean;
}
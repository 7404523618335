import axios, { AxiosResponse } from "axios";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility";

interface ICurrencyDTO {
    title: string;
    symbol: string;
}

export default class AdminCurrenciesService {
    getPaged(filters: {}, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(axios.get(`Admin/Currency${mapQueryString({ ...filters, ...paginationQuery })}` ));
    }

    getById(currencyId: string): Promise<ICurrencyDTO> {
        return mapToDataOnly<ICurrencyDTO>(axios.get(`Admin/Currency/${currencyId}` ));
    }

    update(body: ICurrencyDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.put(`/Admin/Currency`, body));
    }

    delete(id: string): Promise<AxiosResponse> {
        return mapToDataOnly(axios.delete(`/Admin/Currency/${id}`));
    }

    create(body: ICurrencyDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.post(`/Admin/Currency`, body));
    }

    setMainCurrency(id: string): Promise<AxiosResponse> {
        return mapToDataOnly(axios.put(`/Admin/Currency/Main/${id}`));
    }

    rateSetting(body): Promise<any> {
        return mapToDataOnly(axios.put(`/Admin/CurrencyRate/UpdateByCurrencyId`, body));
    }

    getLastRate(currencyId: string): Promise<CurrencyRateDTO> {
        return mapToDataOnly(axios.get(`/Admin/CurrencyRate/${currencyId}`));
    }
}
interface CurrencyRateDTO {
    currencyId: string;
    dollarRate: number;
    title: string;
    isManual: boolean;
}
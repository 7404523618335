import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility";

export default class ConfigurationService {
    get(): Promise<IGetShopApiConfigDTO> {
        return mapToDataOnly<IGetShopApiConfigDTO>(axios.get(`/Buyer/ShopConfig`))
    }

    getFullInformation(): Promise<IGetShopApiConfigDTO> {
        return mapToDataOnly<IGetShopApiConfigDTO>(axios.get(`/Buyer/ShopConfig/FullInformation`))
    }

    activeShop(): Promise<any> {
        return mapToDataOnly<any>(axios.post(`/Buyer/ShopConfig`))
    }

    updateWebHooks(body: ISetShopApiConfigDTO): Promise<any> {
        return mapToDataOnly<any>(axios.put(`/Buyer/ShopConfig`, body))
    }

    reGenerate(): Promise<any> {
        return mapToDataOnly<any>(axios.put(`/Buyer/ShopConfig/SecretKey/Generator`))
    }

    reSendProductList(): Promise<any> {
        return mapToDataOnly<any>(axios.post(`/Buyer/ShopConfig/ReSend`))
    }
}

export interface IGetShopApiConfigDTO {
    title: string
    apiKey: string
    secretKey: string
    orderProductChangeStatusWebHookPath: string
    productChangeStatusWebHookPath: string
    walletDepositWebHookPath: string
    takeoutInventoryWebHookPath : string
    takeoutInventoryRefundedWebHookPath : string
    sellReviewRequestCreatedWebHookPath: string
    supportTicketUpdatedWebHookPath: string
    webHookSecurityHeaderKey: string
    webHookSecurityHeaderValue: string
}

export interface ISetShopApiConfigDTO {
    orderProductChangeStatusWebHookPath: string
    productChangeStatusWebHookPath: string
    walletDepositWebHookPath: string
    webHookSecurityHeaderValue: string
    takeoutInventoryWebHookPath : string
    sellReviewRequestCreatedWebHookPath: string
    supportTicketUpdatedWebHookPath: string
    takeoutInventoryRefundedWebHookPath: string
}
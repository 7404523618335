import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import IState, { IProps } from "./AdminHandleTransaction.models";
import AdminWalletsServices from "../../../../../../services/admin/financials/AdminWalletsServices";
import { toast } from "react-toastify";

export default class AdminHandleTransaction extends React.Component<IProps, IState> {
    adminWalletsServices: AdminWalletsServices
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, description: '' }
        this.adminWalletsServices = new AdminWalletsServices()
    }

    submit = () => {
        this.setState({ pendingRequest: true })
        this.adminWalletsServices.confirmTransaction(this.props.transactionId, { description: this.state.description }).then(() => {
            toast.success("The transaction accepted successfully")
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { description, pendingRequest } = this.state

        return <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                value={description}
                                onChange={e => this.setState({ description: e.target.value })} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {pendingRequest ? <Button variant='success' disabled><Spinner size="sm" animation='border' /></Button> :
                    <Button variant='success' onClick={this.submit}>Confirm</Button>}
            </Modal.Footer>
        </Modal>
    }
}
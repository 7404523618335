import axios from "axios"
import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum"
import { mapToDataOnly } from "../common/HttpUtility"

export default class InventoryCategoryService {
    getDetails(categoryId: string): Promise<InventoryCategoryDetailsDTO> {
        return mapToDataOnly<InventoryCategoryDetailsDTO>(axios.get(`/Seller/InventoryCategory/${categoryId}`))
    }

    getAll(): Promise<ISellerInventoryProductCategoryDTO[]> {
        return mapToDataOnly<ISellerInventoryProductCategoryDTO[]>(axios.get(`/Seller/InventoryCategory`))
    }

    update(productCategories: string[]): Promise<any> {
        return mapToDataOnly<any>(axios.post(`/Seller/InventoryCategory`, productCategories))
    }
}

interface InventoryCategoryDetailsDTO {
    categoryTitle: string
}

export interface ISellerInventoryProductCategoryDTO {
    id: string;
    title: string;
    status: InventoryShopBasePlanStatusEnum | null;
    rejectedAt: string | null;
}
import axios, { AxiosResponse } from "axios";
import { GridResult } from "../../components/Grid/Grid.models";
import { ICreateReportDTO } from "../../components/orders/OrderItemReportModal/OrderItemReportModal.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";
import { IBuyOrderItemsFilters, IOrderItemsQuery, IOrderItemsQueryString } from "../../components/orders/OrdersItems/OrderItemsReport.models";
import { IBuyOrderItemFilters, IBuyOrderItemQuery } from "../../components/orders/Orders/Report/OrderReport.models";
import { SelectionView } from "../common/Models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import CommonService from "../common/CommonService";
import { BuyOrderListItem, ISellOrdersPaginationFilters } from "../../components/orders/Orders/OrdersList/OrdersList.models";
import { BuyOrderListItemFilters } from "../../components/orders/Orders/BuyOrder/BuyOrder.models";
import { ISellOrderItemsFilters } from "../../components/orders/Orders/sell-orders/SellOrderItems/SellOrderItems.models";

export default class OrdersService {
    download(id: string, fileName: string): Promise<void | AxiosResponse<any, any>> {
        return axios
            .get(`/Buyer/Order/Details/Full/Excel/${id}`, { responseType: 'blob' })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });

    }
    order(dto: { addToInventory: boolean }): Promise<string> {
        return mapToDataOnly<string>(axios.post(`/Buyer/Order`, dto));
    }
    getOrderItems(orderId: string, isSafeMode: boolean) {
        if (isSafeMode)
            return mapToDataOnly<{}>(axios.get(`/Buyer/Order/Details/Full?orderid=${orderId}&productid=00000000-0000-0000-0000-000000000000&pageskip=0&pagesize=200`));
        else
            return mapToDataOnly<{}>(axios.get(`/Buyer/Order/Details?orderid=${orderId}&productid=00000000-0000-0000-0000-000000000000&pageskip=0&pagesize=200`));
    }
    getBuyOrders(filters: IBuyOrderItemFilters, paginationQuery: PaginationQuery): Promise<GridResult<BuyOrderListItem>> {
        let url = `/Buyer/OrderReport${mapQueryString({ ...filters, ...paginationQuery })}`
        return mapToDataOnly<GridResult<BuyOrderListItem>>(axios.get(url));
    }
    getSellOrders(filters: ISellOrdersPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<SellerOrderRowDTO>> {
        return mapToDataOnly<GridResult<SellerOrderRowDTO>>(axios.get(`/Seller/Order${mapQueryString({ ...filters, ...paginationQuery })}`));
    }
    getSellOrderDetails(orderId: string): Promise<SellerOrderDetailsDTO> {
        return mapToDataOnly<SellerOrderDetailsDTO>(axios.get(`/Seller/Order/${orderId}`));
    }
    getSellOrderDetailProductInfo(query: GetPagedOrderProductDetailsQueryString, fullInformation?: boolean): Promise<GridResult<SellerOrderProductDetailsRowDTO>> {
        return mapToDataOnly<GridResult<SellerOrderProductDetailsRowDTO>>(axios.get(`/Seller/Order/Details` + (fullInformation === true ? "/Full" : "") + `${mapQueryString(query)}`));
    }
    getDetails(orderId: string) {
        return mapToDataOnly<{}>(axios.get(`/Buyer/Order/${orderId}`));
    }

    getStatistics(shopId: string) {
        return mapToDataOnly<OrderStatisticsDto>(axios.get(`/MarketPlace/Order/Statistics/${shopId}`));
    }

    getSaleRelativeStatistics(shopId: string) {
        return mapToDataOnly<OrderRelativeStatisticsDto>(axios.get(`/MarketPlace/Order/RelativeStatistics/Sale/${shopId}`));
    }
    getBuyRelativeStatistics(shopId: string) {
        return mapToDataOnly<OrderRelativeStatisticsDto>(axios.get(`/MarketPlace/Order/RelativeStatistics/Buy/${shopId}`));
    }
    getOrderProducts(query: IOrderItemsQuery): Promise<GridResult<BuyOrderListItem>> {
        return mapToDataOnly<GridResult<BuyOrderListItem>>(axios.get(`/Buyer/OrderProduct?pageskip=${query.pageskip - 1}&pagesize=${query.pagesize}&title=${query.title}&information=${query.information}&orderNumber=${query.orderNumber}`));
    }

    getOrderProductsFullInformation(query: IOrderItemsQuery): Promise<GridResult<BuyOrderListItem>> {
        let url = `/Buyer/OrderProduct/FullInformation?pageskip=${query.pageskip - 1}&pagesize=${query.pagesize}&title=${query.title}&information=${query.information}`;
        return mapToDataOnly<GridResult<BuyOrderListItem>>(axios.get(url));
    }

    sendReport(dto: ICreateReportDTO): Promise<{}> {
        return mapToDataOnly<{}>(axios.post(`/Buyer/OrderProduct/Support`, dto));
    }

    getBuyOrderItems(filters: IBuyOrderItemsFilters, paginationQuery: PaginationQuery): Promise<GridResult<BuyOrderListItem>> {
        let url = `/Buyer/OrderItemReport${mapQueryString({ ...filters, ...paginationQuery })}`
        return mapToDataOnly<GridResult<BuyOrderListItem>>(axios.get(url));
    }

    getSellOrderItems(filters: ISellOrderItemsFilters, paginationQuery: PaginationQuery): Promise<GridResult<ISellOrderItemTableRow>> {
        let url = `/OrderItem/Sell${mapQueryString({ ...filters, ...paginationQuery })}`
        return mapToDataOnly<GridResult<ISellOrderItemTableRow>>(axios.get(url));
    }

    downloadExcelBuyOrderItems(filters: IBuyOrderItemsFilters): Promise<void | AxiosResponse<any, any>> {
        var commonService = new CommonService();
        let url = `/Buyer/OrderItemReport/Excel${mapQueryString({ ...filters, pageskip: 1, pagesize: 1 })}`
        return commonService.export(url, 'order-items.xlsx')
    }

    downloadExcelBuyOrders(filters: BuyOrderListItemFilters): Promise<void | AxiosResponse<any, any>> {
        var commonService = new CommonService();
        let url = `/Buyer/OrderReport/Excel${mapQueryString({ ...filters, pageskip: 1, pagesize: 1 })}`;
        return commonService.export(url, 'orders.xlsx')
    }

    getBuyOrdersReport(query: IBuyOrderItemQuery): Promise<GridResult<BuyOrderListItem>> {
        let url = `/Buyer/OrderReport${mapQueryString(query)}`
        return mapToDataOnly<GridResult<BuyOrderListItem>>(axios.get(url));
    }

    getProductsTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`/Buyer/Product/TitleValue`));
    }
}


export interface OrderStatisticsDto {
    saleOrderItemCount: number
    saleReviewRequestPercent: number
    buyOrderItemCount: number
    buyReviewRequestPercent: number
}
export interface OrderRelativeStatisticsDto {
    totalRelativeOrderItemCount: string
    totalReviewRequestPercent: number
    orderItemCount: number
    reviewRequestPercent: number
}

export interface SellerOrderRowDTO {
    id: string;
    totalAmount: number;
    totalShareAmount: number;
    totalCashoutAmount: number;
    createAt: string;
    buyerName: string;
    buyerId: string;
    orderNumber: string;
    status: number;
}

export interface SellerOrderDetailsProductDTO {
    productId: string;
    title: string;
    count: number;
    amount: number;
    shareAmount: number;
    cashoutAmount: number;
    isComplete: boolean;
    deliveredCount: number;
    refundCount: number;
    pendingCount: number;
    unitPrice: number;
}

export interface SellerOrderDetailsDTO {
    orderNumber: string;
    status: number;
    orderDate: Date;
    buyerTitle: string;
    buyerId: string;
    totalAmount: number;
    totalCashoutAmount: number;
    totalRefund: number;
    totalShare: number;
    products: SellerOrderDetailsProductDTO[];
}


export interface SellerOrderProductDetailsRowDTO {
    informations: string;
    deliveryAt?: string | null;
    status: number;
}


export interface GetPagedOrderProductDetailsQueryString extends PaginationQuery {
    orderId: string;
    productId: string;
    information?: string;
    status?: number;
}


export interface ISellOrderItemTableRow {
    reviewRequestId:string;
    productId: string;
    orderNumber: string;
    productTitle: string;
    orderDate: string;
    informations: string[]
    status: number;
    sellerPrice: number;
}
import axios from "axios";
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly, mapQueryString } from "../../common/HttpUtility";

interface ISupportTicketDepartmentRowDTO {
    id: string;
    title: string;
    isActive: boolean;
    orderId: number;
}

interface ICreateSupportTicketDepartmentDTO {
    title: string;
    isActive: boolean;
    orderID: number;
}
interface IChangeSupportTicketDepartmentDTO {
    departmentId: string;
    SupportTicketId: string;
}

export default class AdminSupportTicketDepartementService {
    getPaged(filters: {}, paginationQuery: PaginationQuery): Promise<GridResult<ISupportTicketDepartmentRowDTO>> {
        return mapToDataOnly<GridResult<ISupportTicketDepartmentRowDTO>>(axios.get(`Admin/SupportTicketDepartment${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getById(departementId: string): Promise<{ orderID: number, title: string, isActive: boolean }> {
        return mapToDataOnly<any>(axios.get(`Admin/SupportTicketDepartment/Update/${departementId}`));
    }

    create(body: ICreateSupportTicketDepartmentDTO): Promise<any> {
        return mapToDataOnly<any>(axios.post(`Admin/SupportTicketDepartment`, body));
    }

    update(id: string, body: ICreateSupportTicketDepartmentDTO): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Admin/SupportTicketDepartment`, { id, ...body }));
    }

    remove(id: string): Promise<any> {
        return mapToDataOnly<any>(axios.delete(`Admin/SupportTicketDepartment/${id}`));
    }

    changeDepartment(body: IChangeSupportTicketDepartmentDTO): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Admin/SupportTicket/ChangeDepartment`, body));
    }
}
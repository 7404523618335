import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility";

export default class NotificationService {
    getList(): Promise<{ notifications: NotificationView[] }> {
        return mapToDataOnly<{ notifications: NotificationView[] }>(axios.get(`/MarketPlace/Notification?channel=1`));
    }

    seenAll(): Promise<any> {
        return mapToDataOnly<any>(axios.put(`/MarketPlace/Notification/SeenAll`));
    }

    getUnseenCount(): Promise<number> {
        return mapToDataOnly<number>(axios.get(`/MarketPlace/Notification/Unseen/Count`));
    }    
}

export class NotificationView {
    id: string
    createDate: string
    subject: string
    message: string
    seen: boolean
    channel: MessageChannelType
}

enum MessageChannelType {
    Sms,
    Telegram,
    Email,
    System
}
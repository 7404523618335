import axios from "axios"
import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { ShopStatusEnum } from "../../../components/shops/ShopStatusEnum"

let baseUrl = 'Admin/Shop'

export default class AdminShopService {
    get(query: IShopFilters, paginationQuery: PaginationQuery): Promise<GridResult<any>> {
        return mapToDataOnly<any>(axios.get(`${baseUrl}?pageskip=${paginationQuery.pageskip - 1}&pagesize=${paginationQuery.pagesize}` + (query.status === undefined ? '' : `&status=${query.status}`)))
    }
    accept(shopId: string): Promise<void> {
        return mapToDataOnly<any>(axios.put(`${baseUrl}`, { shopId, shopStatus: 1 }))
    }
}

interface IShopFilters {
    status?: ShopStatusEnum
}
import axios from 'axios'
import { mapToDataOnly } from '../../common/HttpUtility'

export interface configDto {
  key: string; value: any
}

export default class AdminSupportTicketConfigService {
  get(): Promise<{ key: string; value: any }[]> {
    return mapToDataOnly<{ key: string; value: any }[]>(
      axios.get(`Admin/SupportTicketConfig`),
    )
  }

  set(dto: configDto[]): Promise<void> {
    return mapToDataOnly<void>(
      axios.put(`Admin/SupportTicketConfig`, dto),
    )
  }
}

import axios from 'axios'
import { mapToDataOnly } from '../common/HttpUtility'

export default class ArticleService {
  getArticle(categoryId?: string): Promise<IArticleDTO[]> {
    return mapToDataOnly<IArticleDTO[]>(
      axios.get(
        `MarketPlace/Article?categoryId=${categoryId}`,
      ),
    )
  }

  getArticleCategory(): Promise<articleCategories[]> {
    return mapToDataOnly<any>(axios.get(`MarketPlace/ArticleCategory`))
  }
}

export interface IArticleDTO {
  id: string
  title: string
  details: string
  isActive: boolean
  imageName: string
  imageSrc: string
  createAt: string
  categoryId: string
  categoryTitle: string
}

export interface articleCategories {
  id: string
  title: string
  orderId: string
  isActive: boolean
  imageName: string
  imageSrc: string
  parentId: string | null
  parentTitle: string | null
  createAt: string
}

import axios from "axios"
import { FinancialAccountStatusEnum } from "../../components/financials/Enums/FinancialAccountStatusEnum";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility"
import { SelectionView } from "../common/Models";

export default class FinancialManagementService {
    getPaged(filters: IExternalWalletsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<IFinancialAccountRowDTO>> {
        return mapToDataOnly<GridResult<IFinancialAccountRowDTO>>(axios.get(`/Buyer/FinancialAccount${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getById(financialAccountId: string): Promise<IFinancialAccountRowDTO> {
        return mapToDataOnly<IFinancialAccountRowDTO>(axios.get(`/Buyer/FinancialAccount/${financialAccountId}`));
    }

    getTypesTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`/Buyer/FinancialAccountType?pageskip=${0}&pagesize=${100}`));
    }

    create(dto: ICreateFinancialAccountDTO): Promise<{}> {
        return mapToDataOnly<{}>(axios.post(`/Buyer/FinancialAccount`, dto));
    }

    update(financialAccountId: string, dto: IUpdateFinancialAccountDTO): Promise<{}> {
        return mapToDataOnly<{}>(axios.put(`/Buyer/FinancialAccount/${financialAccountId}`, dto));
    }

    delete(financialAccountId: string): Promise<{}> {
        return mapToDataOnly<{}>(axios.delete(`/Buyer/FinancialAccount/${financialAccountId}`));
    }

    getVerifyAmount(financialAccountId: string): Promise<{ amount: number, expireAt: string, cashPaymentAccountNumber: string }> {
        return mapToDataOnly<{ amount: number, expireAt: string, cashPaymentAccountNumber: string }>(axios.get(`/Marketplace/FinancialAccount/GenerateVerifyAmount/${financialAccountId}`));
    }

    checkPayment(transactionNumber: string, financialAccountId: string): Promise<any> {
        let body = {
            financialAccountId,
            transactionNumber,
        }

        return mapToDataOnly<any>(axios.put(`/Marketplace/FinancialAccount/CheckVerified`, body));
    }
}

export interface IExternalWalletsPaginationFilters {

}

interface IFinancialAccountRowDTO {
    id: string;
    createAt: string;
    status: FinancialAccountStatusEnum;
    accountIdentity: string;
    nickName: string;
    typeTitle: string;
}

interface ICreateFinancialAccountDTO {
    nickName: string,
    financialAccountTypeId: string,
    accountIdentity: string
}


interface IUpdateFinancialAccountDTO {
    nickName: string
}


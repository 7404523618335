import axios from "axios";
import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum";
import { mapQueryString, mapToDataOnly } from "../common/HttpUtility";
import { OfferStatusEnum } from "../../components/orders/offers/OfferStatusEnum";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { GridResult } from "../../components/Grid/Grid.models";

export default class OffersService {
    getActiveOffers(categoryId?: string): Promise<GridResult<OwnedOfferDto>> {
        if (categoryId)
            return mapToDataOnly<GridResult<OwnedOfferDto>>(axios.get(`/Offer/?PageSize=1000&PageSkip=0&status=0&productCategoryId=${categoryId}`));
        else
            return mapToDataOnly<GridResult<OwnedOfferDto>>(axios.get(`/Offer/?PageSize=1000&PageSkip=0&status=0`));
    }

    get(filters: any, paginationQuery: PaginationQuery): Promise<GridResult<OwnedOfferDto>> {
        return mapToDataOnly<GridResult<OwnedOfferDto>>(axios.get(`/Offer/${mapQueryString({ ...filters, ...paginationQuery })}&status=0&status=1&status=2&status=3`));
    }

    getBuyOffers(categoryId?: string): Promise<OfferRowDto[]> {
        if (categoryId)
            return mapToDataOnly<OfferRowDto[]>(axios.get(`/Offer/Buy?categoryId=${categoryId}`));
        else
            return mapToDataOnly<OfferRowDto[]>(axios.get(`/Offer/Buy`));
    }

    getBuyOffersPagination(filters: any, paginationQuery: PaginationQuery): Promise<GridResult<OfferRowDto>> {
        return mapToDataOnly<GridResult<OfferRowDto>>(axios.get(`/Offer/Buy/Pagination${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    create(dto: CreateOfferDto): Promise<any> {
        return axios.post(`/Offer`, dto);
    }

    cancel(id: string): Promise<any> {
        return axios.delete(`/Offer/Cancel/${id}`);
    }
}
export interface IGetBuyOffersQueryString extends PaginationQuery { categoryId?: string }

export interface CreateOfferDto {
    productId: string,
    quantity: number,
    price: number,
    addToInventory: boolean
}


export interface OfferRowDto {
    productId?: string,
    productTitle?: string,
    quantity: number,
    inCommingCount: number,
    sellerBlockCount: number,
    buyerBlockCount: number,
    orderingPlanStatus: InventoryShopBasePlanStatusEnum;
    availableInventory: number,
    outOfPriceRangeCount: number,
}

export interface OwnedOfferDto {
    id: string,
    productId?: string,
    expireAt?: string,
    closedAt?: string,
    quantity: number,
    acceptedCount: number,
    price: number,
    isFixed: boolean,
    status: OfferStatusEnum,
    productTitle: string
}
import React from "react"
import AdminWalletsServices, { IWalletChargeDTO } from "../../../../../services/admin/financials/AdminWalletsServices"
import IState, { IProps } from "./AdminWalletCharge.models"
import PageTitle from "../../../../shared/PageTitle/PageTitle"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import FormatMoney from "../../../../utilities/FormatMoney"
import ValidAmount from "../../../../utilities/Amount/ValidAmount"

export default class AdminWalletCharge extends React.Component<IProps, IState> {
  walletsServices: AdminWalletsServices

  constructor(props: any) {
    super(props)
    this.state = { pendingRequest: false, amount: '0', description: '', balance: 0, title: '' }
    this.walletsServices = new AdminWalletsServices()
  }

  componentDidMount(): void {
    this.getStatus()
  }

  submit = () => {
    let { amount, description } = this.state

    let body: IWalletChargeDTO = {
      amount: Number(amount),
      description,
      walletId: this.props.walletId
    }

    this.setState({ pendingRequest: true })

    this.walletsServices.charge(body).then(() => {
      this.clear()
      toast.success('The wallet charged successfully.')
      this.getStatus()
    }).finally(() => {
      this.setState({ pendingRequest: false })
    })
  }

  getStatus = () => {
    this.walletsServices.getStatus(this.props.walletId).then((response) => {
      this.setState({ title: response.title, balance: response.balance })
    })
  }

  clear = () => this.setState({ amount: '0', description: "" })

  render(): React.ReactNode {
    let { amount, balance, description, title, pendingRequest } = this.state

    return (
      <div>
        <PageTitle title="Admin - Wallet Charge" /> <hr />
        <Form>
          <Row>
            <Col md="4" sm="12"> Wallet: <b>{title}</b> </Col>
            <Col md="4" sm="12"> Balance: <b>{FormatMoney(balance)}</b> </Col>
          </Row>
          <Row className="mt-3">
            <Col md="4" sm="12">
              <Form.Label>Amount:</Form.Label>
              <Form.Control
                type="text"
                inputMode='decimal' value={amount} onChange={e => ValidAmount(e.target.value) && this.setState({ amount: e.target.value })} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="4" sm="12">
              <Form.Label>Description:</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter description"
                value={description}
                onChange={e => this.setState({ description: e.target.value })} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {
                pendingRequest ? <Button variant="success" disabled><Spinner animation="border" size="sm" /></Button> :
                  <Button onClick={this.submit} variant="success">Charge</Button>
              }
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}
import axios from "axios"
import { mapToDataOnly } from "../common/HttpUtility"

export default class CashPaymentService {
    updateCashPaymentAverage(dto: IChangeCashPaymentAverage): Promise<{}> {
        return mapToDataOnly<{}>(axios.put(`/Buyer/CashPayment`, dto));
    }
}

interface IChangeCashPaymentAverage {
    currencyId: string
    value: number
}

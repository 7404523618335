import axios from "axios";
import { mapToDataOnly } from "../../common/HttpUtility";

export default class AdminNotificationPermissionService {
    get(): Promise<NotificationPermissionRowDto[]> {
        return mapToDataOnly<NotificationPermissionRowDto[]>(axios.get(`/Admin/NotificationPermission`));
    }

    update(body: UpdateNotificationPermission[]): Promise<any> {
        return mapToDataOnly<any>(axios.put(`/Admin/NotificationPermission`, body));
    }
}

export class NotificationPermissionRowDto {
    id: string
    key: string
    title: string
    isActive: boolean
}

export class UpdateNotificationPermission {
    isActive: boolean
    permissionId: string
}
import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility";
import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum";

export interface CatalogDto {
    title: string
    products: ProductCatalogDto[]
}

export interface ProductCatalogDto {
    title: string
    currencyId: string
    imageId: string | null
    numberOfSell: number
    productId: string
    realPrice: number
    ownedSellPlan: {
        status: InventoryShopBasePlanStatusEnum,
        delayDescription: string,
        availableInventoryCount: number,
        plans: [
            {
                isApiPlan: boolean,
                countFrom: number,
                countTo: number,
                price: number
                discount: number
            }]
    }
    sellers: [
        {
            delayDescription: string | null
            inventoryCount: number | null
            plans: [
                {
                    isApiPlan: boolean,
                    countFrom: number,
                    countTo: number,
                    price: number
                    discount: number
                }]
            sellerShopId: string
            totalSoldCount: string
            productSoldCount: string
            title: string
        }
    ]
}



export interface ProductCategoryTitleView {
    title: string;
    parentId?: string;
    id: string;
}

export default class ProductCatalogService {

    getPrices(productId: string): Promise<ProductCatalogDto> {
        return mapToDataOnly<any>(axios.get(`MarketPlace/Product/Prices/${productId}`));
    }

    getAvailableCountByShopId(productId: string, sellerShopId: string): Promise<InventoryAvailableCountDetailsDto> {
        return mapToDataOnly<InventoryAvailableCountDetailsDto>(axios.get(`MarketPlace/Product/AvailableCount?productId=${productId}&sellerShopId=${sellerShopId}`));
    }

    getProductCategories(): Promise<ProductCategoryTitleView[]> {
        return mapToDataOnly<ProductCategoryTitleView[]>(axios.get(`/MarketPlace/ProductCategory/MultiLevel`));
    }
}

interface InventoryAvailableCountDetailsDto {
    hasOrderIdCount: number,
    totalCount: number
}
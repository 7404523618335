import axios from "axios"
import { mapToDataOnly } from "../common/HttpUtility"

export default class CartService {
    add(cartItem: {
        count: number
        productId: string
        sellerShopId: string
        unitPrice: number
    }): Promise<string> {

        return mapToDataOnly<string>(axios.post(`Buyer/ProductBasket`, cartItem));
    }

    update(cartItem: {
        count: number
        productBasketId: string
    }): Promise<string> {

        return mapToDataOnly<string>(axios.put(`Buyer/ProductBasket`, cartItem));
    }

    delete(id: string): Promise<string> {

        return mapToDataOnly<string>(axios.delete(`Buyer/ProductBasket/${id}`));
    }

    get(): Promise<{
        count: number
        id: string
        imageId: string | null
        productTitle: string
        productInventoryAvailableCount: number
        productId: string
        sellerShopId:string
        unitPrice: number
    }[]> {

        return mapToDataOnly<any>(axios.get(`Buyer/ProductBasket`));
    }
}
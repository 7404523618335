import React, { RefObject } from "react"
import AdminWalletsServices from "../../../../../services/admin/financials/AdminWalletsServices"
import DynamicGrid from "../../../../shared/DynamicGrid/DynamicGrid"
import IState, { GetPagedWalletTransactionQueryString, IProps } from "./AdminWalletTransaction.models"
import { GridAction, GridColumn, GridColumnTypes } from "../../../../Grid/Grid.models"
import walletTransactionStatusDisplayBadge, { WalletTransactionStatusEnum } from "../Enums/WalletTransactionStatusEnum"
import PageTitle from "../../../../shared/PageTitle/PageTitle"
import AdminHandleTransaction from "./Handle/AdminHandleTransaction"
import AdminWalletTransactionDetails from "./Details/AdminWalletTransactionDetails"

export default class AdminWalletTransaction extends React.Component<IProps, IState> {
  dynamicGrid: RefObject<DynamicGrid<GetPagedWalletTransactionQueryString>>
  walletsServices: AdminWalletsServices

  constructor(props: any) {
    super(props)
    this.dynamicGrid = React.createRef()
    this.walletsServices = new AdminWalletsServices()
    this.state = {}
  }

  componentDidMount = () => this.dynamicGrid.current.fetch(this.dynamicGrid.current.state.pageSize)

  render(): React.ReactNode {
    let { walletTransactionId, transactionDetailsId } = this.state

    let gridColumns: GridColumn[] = [
      { title: "Amount", key: "amount", type: GridColumnTypes.Amount },
      { title: "Status", key: "status", type: GridColumnTypes.Enum },
      { title: "Member", key: "memberName" },
      { title: "Description", key: "description" },
      { title: "CreateAt", key: "createAt", type: GridColumnTypes.Date },
    ]

    let gridActions: GridAction[] = [
      {
        caption: "Done", variant: "outline-success", skipSpinner: true, checkIsDisabled: (row) => {
          return row.status !== WalletTransactionStatusEnum.Pending
        }, onClick: (row) => { this.setState({ walletTransactionId: row.id }) }
      },
      { caption: "Details", variant: "outline-info", skipSpinner: true, onClick: (row) => { this.setState({ transactionDetailsId: row.id }) } },
    ]

    return (
      <>
        <PageTitle title="Admin - Wallet Transactions" /> <hr />
        <DynamicGrid<GetPagedWalletTransactionQueryString>
          ref={this.dynamicGrid}
          columns={gridColumns}
          actions={gridActions}
          size="sm"
          onFetch={this.walletsServices.getTransaction}
          hideFilterBar
          defaultFilters={{ walletId: this.props.walletId }}
          refactorRow={(row) => {
            return { ...row, status: walletTransactionStatusDisplayBadge(row.status) }
          }}
        />
        {walletTransactionId != undefined && <AdminHandleTransaction transactionId={walletTransactionId} onClose={() => { this.setState({ walletTransactionId: undefined }) }} />}
        {transactionDetailsId != undefined && <AdminWalletTransactionDetails transactionId={transactionDetailsId} onClose={() => { this.setState({ transactionDetailsId: undefined }) }} />}
      </>
    )
  }
}
import axios, { AxiosResponse } from "axios"
import { GridResult } from "../../../components/Grid/Grid.models"
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models"
import { mapToDataOnly } from "../../common/HttpUtility"
import { SelectionView } from "../../common/Models"

let baseUrl = 'Admin/ProductCategory'

export default class AdminProductCategoriesService {
    getPaged(): Promise<GridResult<any>> {
        return mapToDataOnly<any>(axios.get(`${baseUrl}`))
    }
    
    update(id: string, dto: ProductCategoryDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.put(`${baseUrl}/${id}`, dto));
    }

    delete(id: string): Promise<AxiosResponse> {
        return mapToDataOnly(axios.delete(`${baseUrl}/${id}`));
    }

    getCategory(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(axios.get(`${baseUrl}/TitleValue`))
    }

    add(dto: ProductCategoryDTO): Promise<AxiosResponse> {
        return mapToDataOnly(axios.post(`${baseUrl}`, dto));
    }

    getById(id: string): Promise<ProductCategoryDTO> {
        return mapToDataOnly<ProductCategoryDTO>(axios.get(`${baseUrl}/${id}`))
    }
}

export interface ICategory {
    rows: {
        id: string,
        title: string,
        orderId: number,
        isActive: boolean,
        parentId: string | null,
        parentTitle: string | null,
        sellerCount: number,
        productCount: number,
        imageName: null
    },
    pageSize: number
  }

export interface ProductCategoryDTO {
    title: string;
    orderId: number;
    isActive: boolean;
    parentId: string | null;
    imageSrc?: string | null;
    imageName: string | null;
}
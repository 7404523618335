import axios from "axios";
import { GridResult } from "../../../components/Grid/Grid.models";
import { PaginationQuery } from "../../../components/Grid/Pagination/Pagination.models";
import { mapQueryString, mapToDataOnly } from "../../common/HttpUtility";
import { WalletTransactionStatusEnum } from "../../../components/financials/Enums/WalletTransactionStatusEnum";
import { SelectionView } from "../../common/Models";

interface GetPagedWalletTransactionQueryString {
    walletId: string;
}

export interface WalletTransactionRowDTO {
    amount: number;
    status: WalletTransactionStatusEnum;
    description: string;
    memberUserId: string;
    memberName: string;
    createAt: string;
    isOutTransaction: boolean;
}

export interface WalletChargeStatusDTO {
    walletOwnerId: string | null;
    title: string;
    balance: number;
    blockedBalance: number;
    walletCurrencyTitle: string;
    walletCurrencyId: string;
}

export interface IWalletChargeDTO {
    walletId: string;
    amount: number;
    description: string;
}

export interface GlobalWalletBalanceDTO {
    id: string;
    key: string;
    value: number;
}

interface WalletTransferFund {
    amount: number,
    description: string,
    sourceWalletId: string,
    destinationWalletId: string,
}

interface OverviewStatistic {
    positiveAverage: number
    negativeAverage: number
    average: number
}

export interface IFiltersGetWallets {
    onlyNegativeWallet?: boolean
    ownerId?: string
    onlyGlobalWallet?: boolean
}

export interface WalletTransactionDTO {
    status?: WalletTransactionStatusEnum;
    fromWalletDescription?: string;
    toWalletDescription?: string;
    acceptorUserId?: string;
    acceptDescription?: string;
    memberUserId?: string;
    createAt: string;
    toWalletId?: string | null;
    fromWalletId?: string | null;
    toShopId?: string | null;
    fromShopId?: string | null;
    sourceAmount: number;
    destinationAmount: number;
    isRefund: boolean;
    fromShopTitle?: string;
    toShopTitle?: string;
    acceptorUser: string;
    memberName?: string
}

export default class AdminWalletsServices {
    getPaged(filters: IFiltersGetWallets, paginationQuery: PaginationQuery): Promise<any> {
        return mapToDataOnly<any>(axios.get(`Admin/Wallet${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getTransaction(filters: GetPagedWalletTransactionQueryString, paginationQuery: PaginationQuery): Promise<GridResult<WalletTransactionRowDTO>> {
        return mapToDataOnly<GridResult<WalletTransactionRowDTO>>(axios.get(`Admin/WalletTransaction${mapQueryString({ ...filters, ...paginationQuery })}`));
    }

    getTransactionById(transactionId: string): Promise<WalletTransactionDTO> {
        return mapToDataOnly<WalletTransactionDTO>
            (axios.get(`Admin/WalletTransaction/${transactionId}`));
    }

    confirmTransaction(walletId: string, body): Promise<WalletChargeStatusDTO> {
        return mapToDataOnly<WalletChargeStatusDTO>(axios.put(`Admin/WalletTransaction/Confirm/${walletId}`, body));
    }

    getStatus(walletId: string): Promise<WalletChargeStatusDTO> {
        return mapToDataOnly<WalletChargeStatusDTO>(axios.get(`Admin/WalletCharge/${walletId}`));
    }

    charge(body: IWalletChargeDTO): Promise<any> {
        return mapToDataOnly<any>(axios.post(`Admin/WalletCharge`, body));
    }

    lockWallet(body: { description: string, isLock: boolean }, id: string): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Admin/Wallet/LockWalletHandler/${id}`, body));
    }

    getGlobalWalletBalances(): Promise<GlobalWalletBalanceDTO[]> {
        return mapToDataOnly<GlobalWalletBalanceDTO[]>(axios.get(`Admin/Wallet/GlobalWalletBalances`));
    }

    getOverviewStatistic(): Promise<OverviewStatistic> {
        return mapToDataOnly<OverviewStatistic>(axios.get(`Admin/Wallet/OverviewStatistic`));
    }

    transferFund(body: WalletTransferFund): Promise<any> {
        return mapToDataOnly<any>(axios.post(`Admin/WalletTransaction/Transfer`, body));
    }

    getTitleValues(): Promise<SelectionView[]> {
        let url = `/Admin/Wallet/TitleValues`
        return mapToDataOnly<SelectionView[]>(axios.get(url));
    }

    exchange(body: { fromWalletId: string, toWalletId: string, amount: string, description: string }): Promise<any> {
        let url = `/Admin/ExchangeTransaction`
        return mapToDataOnly<any>(axios.post(url, body));
    }

    create(title, currencyId): Promise<string> {
        let url = `/Admin/Wallet/Global`
        return mapToDataOnly<string>(axios.post(url, { key: title, currencyId }));
    }
}
import React from "react"
import { Alert, Dropdown, Spinner } from "react-bootstrap"
import { Bell } from "react-bootstrap-icons"
import { Navigate, NavLink } from "react-router-dom"
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext"
import InventoryShopCollectionService from "../../../services/inventory/InventoryShopCollectionService"
import OrderProductService from "../../../services/orders/OrderProductService"
import IState, { IProps } from './Alerts.models'
import './Alerts.scss'
import NotificationService from "../../../services/notifications/NotificationService"
import HubridMoment from "../../utilities/HybridMoment"
import SupportTicketsService from "../../../services/helpCenter/SupportTicketService"
import ReviewRequestService from "../../../services/orders/ReviewRequestService"

export default class Alerts extends React.PureComponent<IProps, IState> {
    reviewRequestService: ReviewRequestService
    orderProductService: OrderProductService
    inventoryShopCollectionService: InventoryShopCollectionService
    notificationService: NotificationService
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    constructor(props: IProps) {
        super(props)
        this.reviewRequestService = new ReviewRequestService()
        this.orderProductService = new OrderProductService()
        this.inventoryShopCollectionService = new InventoryShopCollectionService()
        this.notificationService = new NotificationService()
        this.state = { orderCount: 0, pendingCount: 0, reportCount: 0, deficitCount: 0, notifications: [], loading: false, dropdownToggle: false, notificationCount: 0 }
    }

    componentDidMount() {
        this.getData()
        this.context.onReviewRequestChangedStatus = this.getData;

        let interval = setInterval(() => this.loadNotificationCount(), 5000);
        this.setState({ interval: interval })
    }

    componentWillUnmount(): void {
        clearInterval(this.state.interval);
    }

    loadNotificationCount = () => {
        this.notificationService.getUnseenCount().then((response) => {
            this.setState({ notificationCount: response })
        })
    }

    loadNotifications = () => {
        this.setState({ loading: true })
        this.notificationService.getList().then((response) => {
            this.setState({ notifications: response.notifications })
            this.notificationService.seenAll().then(() => this.loadNotificationCount())
        }).finally(() => this.setState({ loading: false }))
    }

    getData = () => {
        this.reviewRequestService.getCount().then((reportCount) => {
            this.orderProductService.getOrderCount().then((orderCount) => {
                this.orderProductService.getPendingCount().then((pendingCount) => {
                    this.inventoryShopCollectionService.getDeficitCount().then((deficitCount) => {
                        this.setState({ orderCount: orderCount.count, pendingCount: pendingCount.count, reportCount: reportCount.count, deficitCount: deficitCount })
                    })
                })
            })
        })
    }

    render(): React.ReactNode {
        const CustomToggle = React.forwardRef((props: any, ref) => (
            <div className="dropdown-custom" onClick={(e) => {
                e.preventDefault();
                props.onClick(e);

                this.setState({ dropdownToggle: !this.state.dropdownToggle }, () => {
                    if (this.state.dropdownToggle)
                        this.loadNotifications()
                })
            }}>{props.children}</div>))

        const CustomMenu = React.forwardRef((props: any, ref: any) => {
            return (
                <div ref={ref} aria-labelledby={props.labeledBy} style={props.style} className={props.className}>
                    {props.children}
                </div>
            )
        });

        let { loading } = this.state
        let totalNotitfication = this.state.reportCount + this.state.notificationCount;

        { this.state.navigateTo && <Navigate to={this.state.navigateTo} /> }
        return (<div className="Alerts">
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <div className="alert-toggle">
                        <Bell />
                        {totalNotitfication > 0 && <span className="red-number">{totalNotitfication}</span>}
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="menu-dropdown" as={CustomMenu}>
                    <h5>Notifications</h5>
                    {/* <h5 className="d-flex justify-content-between align-items-center">
                        <div>Notifications</div>
                        <NavLink to={"/notifications-setting"}><GearWideConnected /></NavLink>
                    </h5> */}
                    {
                        this.state.reportCount > 0 &&
                        <NavLink to={`/orders/sell/?tab=review-request`} data-rr-ui-dropdown-item="" className="dropdown-item p-2">
                            You have {this.state.reportCount} pending review requests.
                        </NavLink>
                    }
                    {
                        this.state.deficitCount > 0 &&
                        <NavLink to={`/inventory/?activeTab=stockIn&defaultActiveSubKey=inComming`} data-rr-ui-dropdown-item="" className="dropdown-item p-2">
                            You have {this.state.deficitCount} incomming inventory collection.
                        </NavLink>
                    }
                    {
                        // (this.state.notificationCount > 0 || this.state.notifications.length > 0) &&
                        <div className={`notifications-area ${this.state.notifications.length > 3 && "scroll"}`}>
                            {
                                loading ? <div className="text-center text-muted p-2"><Spinner animation="border" /></div> :
                                    this.state.notifications.map((notification, index) => {
                                        return (<div className={`${index + 1 < this.state.notifications.length && "border-bottom"} ${notification.seen && "seen"} p-2`} key={index}>
                                            <div className="font-bold">{notification.subject}</div>
                                            <div className="text-muted  font-sm pb-1">{HubridMoment(notification.createDate)}</div>
                                            <div>{notification.message}</div>
                                        </div>
                                        );
                                    })
                            }
                        </div>
                    }
                    <div className="footer-actions">
                        <Dropdown.Item>
                            <span className="warm-grey-text">
                                <NavLink to={"/notifications-setting"}>Setting</NavLink>
                            </span>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>

            {/* {this.state.pendingCount > 0 && this.alertView(`You have ${this.state.pendingCount} pending order products`, '/orders', 'danger', 'Orders')} */}
            {/* {this.state.reportCount > 0 && this.alertView(`You have ${this.state.reportCount} report `, '/orders', 'warning', 'Reports')} */}
        </div>)
    }

    alertView(message: string, to: string, variant: string, btnCaption: string) {
        return <Alert variant={variant} className="mb-2" >
            {message}
            {/* <NavLink to={to}><Button variant={variant}  size="sm">{btnCaption}</Button></NavLink> */}
        </Alert>
    }
}
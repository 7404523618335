import axios from "axios";
import { mapToDataOnly } from "../common/HttpUtility"

export default class InventoryStatisticsService {
    getFinancial(): Promise<InventoryFinancialStatisticsDTO> {
        return mapToDataOnly<InventoryFinancialStatisticsDTO>(axios.get(`/InventoryStatistics`));
    }
}

export interface InventoryFinancialStatisticsDTO {
    sourceTotalBalance: number;
    totalDeficits: number;
    availableAmount: number;
    totalSold: number;
    totalProfit: number;
    totalShared: number;
}

export interface IPeriodDatePickerQueryString {
    fromDate?: string;
    toDate?: string;
}